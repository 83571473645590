<script setup>
const store = appStore();
const route = useRoute();
</script>

<template>
    <router-view name="head" />
    <section class="main-body f-fx">
        <section class="page-body">
            <router-view name="body" />
        </section>
        <section class="page-right" v-if="route.meta.isSidebar && store.token">
            <router-view name="right" />
        </section>
    </section>
</template>

<style lang="less">
#app {
    font-family: PingFang SC, DFPKingGothicGB-Regular, sans-serif; /**Avenir, Helvetica, Arial, */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    /* text-align: center;
  margin-top: 60px;
  background: @body-bg; */
}
.customClass #app,
.chat-room #app {
    color: @color-white;

    width: 1400px;
    margin: auto;
    // overflow-y: hidden;
    background: url(@/assets/abg.jpg) no-repeat center center/cover;
    @media (min-width: 2000px) {
        width: 1600px;
    }
    @media (min-width: 3000px) {
        width: 1800px;
    }
}

.page-body {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.page-right {
    width: 272px;
    padding: 10px 10px 0;
    background-color: #150d3b;
    display: flex;
    flex-direction: column;
}
.main-body {
    height: calc(100vh - 72px);
    min-height: 700px;
    /* flex:1;
  height: 100vh;
  width:100%;
  left:60px;
  top:0; */
    /* overflow-y: scroll; */
}
.main-body::after {
    content: "";
    display: block;
    clear: both;
    overflow: hidden;
}
</style>
