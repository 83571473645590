<script setup>
import {margeParams} from "@/utils";

const store = appStore();
const { followListType } = storeToRefs(store); //当前聊天群ID，当前房间ID

const state = reactive({
    loading: false,
    currentPage: 1,
    pageSize: 20,
    list: [],
    total: 0,
    done: false,
});

const followLoading = ref(false);
const stealthLoading = ref(false);
const isShow = ref(false);

watchEffect(() => {
    if (followListType.value) {
        nextTick(() => {
            isShow.value = true;
        });
    }
});

watch(
    followListType,
    newVal => {
        if (newVal) {
            state.loading = false;
            state.currentPage = 1;
            state.pageSize = 20;
            state.list = [];
            state.done = false;
            getData();
        }
    },
    {
        immediate: true,
    }
);

//关注|取消关注
async function toFollow(userInfo) {
    if (followLoading.value) return;
    followLoading.value = true;

    try {
        const { isFollow, id, uid } = userInfo;
        let resp;
        if (!isFollow) {
            //关注
            resp = await fly.getFollow({
                followUserId: uid || id,
            });
            if (resp && resp.code === 0) {
                if (resp.msg) Toast.success(resp.msg);
                userInfo.isFollow = 1;
            }
        } else {
            //取消关注
            resp = await fly.getUnFollow({
                followUserId: uid || id,
            });
            if (resp && resp.code === 0) {
                if (resp.msg) Toast.success("已取消关注");
                userInfo.isFollow = 0;
            }
        }
    } catch (error) {
        console.log("error :>> ", error);
    }
    followLoading.value = false;
}
//开启|关闭隐身
async function setStealthFootprint(userInfo) {
    if (stealthLoading.value) return;
    stealthLoading.value = true;
    try {
        const { isHide, id, uid } = userInfo;
        const reqData = {
            stealthUserId: uid || id,
            type: isHide ? 2 : 1,
        };
        const { code, msg } = await fly.postOperateStealthFootprint(margeParams(reqData));
        if (code === 0) {
            if (reqData.type === 1) {
                if (msg) Toast.success("已开启隐身访问");
                userInfo.isHide = true;
            } else {
                if (msg) Toast.success("已取消隐身访问");
                userInfo.isHide = 0;
            }
        }
    } catch (error) {
        console.log("error :>> ", error);
    }
    stealthLoading.value = false;
}

const privacy = ref(null); //隐私配置
//获取黑名单数量
async function getUserPrivacy() {
    try {
        const { code, data } = await fly.getUserPrivacy();
        if (code === 0 && data && data.userId === store.userId) {
            privacy.value = data;
        }
    } catch (error) {
        console.log("error :>> ", error);
    }
}

//移除黑名单
async function toRemove(userInfo) {
    if (followLoading.value) return;
    followLoading.value = true;

    try {
        const { removed, userId } = userInfo;
        if (!removed) {
            let resp = await fly.getRemoveBlackList({
                bid: userId,
            });
            if (resp && resp.code === 0) {
                if (resp.msg) Toast.success("已移除");
                userInfo.removed = true;
            }
        }
    } catch (error) {
        console.log("error :>> ", error);
    }
    followLoading.value = false;
}

function preview(userInfo) {
    const { id, uid } = userInfo;
    if (uid || id) {
        store.previewUserInfo(uid || id);
    }
}

//获取数据
async function getData() {
    if (state.loading || state.done) return;
    state.loading = true;
    let getFuc;
    if (followListType.value === 1) getFuc = fly.getFollowList;
    else if (followListType.value === 2) getFuc = fly.getFansList;
    else if (followListType.value === 3) getFuc = fly.getQueryAccessRecordList;
    else if (followListType.value === 4) getFuc = fly.getQueryVisitorRecordList;
    else if (followListType.value === 5) getFuc = fly.getBlackList;
    if (!getFuc) return;

    //获取黑名单人数
    if (followListType.value === 5) getUserPrivacy();

    try {
        const resp = await getFuc({ currentPage: state.currentPage, pageSize: state.pageSize });
        if (resp && resp.code === 0) {
            state.currentPage++;
            let list;
            if (followListType.value === 1) list = resp.data.follows;
            else if (followListType.value === 2) list = resp.data.fans;
            else if (followListType.value === 3) list = resp.data.accessRecordList;
            else if (followListType.value === 4) list = resp.data.visitorRecordList;
            else if (followListType.value === 5) list = resp.data.dataList;
            if (list) {
                if (list.length < state.pageSize) state.done = true;
                if (state.currentPage === 1) {
                    state.list = list;
                } else {
                    state.list.push(...list);
                }
                if (typeof resp.data.total === "number") state.total = resp.data.total;
            }
        }
    } catch (error) {
        console.log("error :>> ", error);
    }
    state.loading = false;
}

//关闭窗口
function close() {
    // isShow.value = false;
    // setTimeout(() => {
    store.$patch(state => {
        state.followListType = 0;
    });
    // }, 200);
}
</script>
<template>
    <section class="follow-list" @click="close">
        <transition name="scale">
            <div class="content follow" v-show="isShow" @click.stop="">
                <img class="close-btn" src="@/assets/follow/close.png" alt="" @click.stop="close" />
                <div class="title-box">
                    <p class="title">{{ ["关注", "粉丝", "最近来访", "浏览足迹", "黑名单"][followListType - 1] }}</p>
                    <p class="visit" v-if="followListType === 3">
                        最近来访次数:
                        <span>{{ state.total }}</span>
                    </p>
                    <p v-if="followListType === 3">
                        查看来访，
                        <span>限免开放</span>
                    </p>
                    <p class="black-count" v-if="followListType === 5 && privacy">
                        <span>{{ privacy.blacklistCount }}/{{ privacy.blackLimitNum }}</span>
                    </p>
                </div>

                <ul class="list" v-scroll @scrollbottom="getData()" @scroll.stop="">
                    <li v-for="(item, index) in state.list" :key="'' + item.uid + index">
                        <img :src="item.headPortrait" alt="" @click="preview(item)" />
                        <div class="user-info" :class="{ 'black-list': followListType === 5 }">
                            <div class="nick-box">
                                <p class="nick">
                                    <span @click="preview(item)">{{ item.remarksName || item.nickname || item.nickName }}</span>
                                    <span class="gender" :class="{ man: item.gender === 1 }">
                                        <img v-if="item.gender === 1" src="@/assets/follow/man.png" alt="" />
                                        <img v-if="item.gender === 2" src="@/assets/follow/female.png" alt="" />
                                        <!-- <span v-if="typeof item.nobility === 'number'">{{ item.nobility }}</span> -->
                                    </span>
                                    <VipImage class="vip-level" :vipLevel="item.vipLevel" :isVipExpired="item.isVipExpired" />
                                </p>
                                <p class="autograph">{{ item.autograph || " " }}</p>
                            </div>
                            <div class="right-box">
                                <div class="time" v-if="followListType === 3 || followListType === 4">{{ item.accessTime }}</div>
                                <div class="follow-handle" v-if="followListType === 5" :class="{ removed: item.removed }" @click.stop="toRemove(item)">{{ item.removed ? "已移除" : "移除" }}</div>
                                <div class="follow-handle" v-if="followListType === 3" :class="{ following: item.isFollow }" @click.stop="toFollow(item)">{{ item.isFollow ? "已关注" : "关注" }}</div>
                                <div class="follow-handle hide" v-if="followListType === 4" :class="{ following: item.isHide }" @click.stop="setStealthFootprint(item)">
                                    {{ item.isHide ? "已隐身" : "对TA隐身" }}
                                </div>
                            </div>
                        </div>
                    </li>
                    <van-loading class="f-tac f-mt20" v-if="state.loading" size="24" type="spinner" color="#999" />
                    <div class="f-tac f-mt20 no-more" v-if="state.done">没有更多了</div>
                </ul>
            </div>
        </transition>
    </section>
</template>
<style lang="less" scoped>
.scrollbar();
.fade();
.follow-list {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    margin: auto;

    .middle();
    background: rgba(0, 0, 0, 0.6);
    .content {
        width: 100%;
        height: 100%;
        max-width: 760px;
        max-height: 605px;

        border-radius: 8px;
        background-color: #0e0d2f;
        background: url(@/assets/mask.png) no-repeat center center/cover;
    }

    .follow {
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        color: white;
        padding: 20px 20px 0 30px;
        // box-shadow: 0px 25px 15px -10px #100e2f inset, 0px 0px, 0px 0px, 0px 0px;
        .close-btn {
            position: absolute;
            right: 15px;
            top: 15px;
            width: 25px;
            padding: 5px;
            cursor: pointer;
        }
        .title-box {
            display: flex;
            align-items: flex-end;

            margin-bottom: 30px;
            > p {
                font-size: 12px;
                color: rgba(255, 255, 255, 0.4);
                line-height: 1.2;

                &.title {
                    position: relative;
                    font-size: 16px;
                    font-weight: bold;
                    color: #ffffff;
                    line-height: 1.2;
                    &::after {
                        content: "";
                        position: absolute;
                        top: calc(100% + 10px);
                        left: 50%;

                        transform: translateX(-50%);

                        width: 65%;
                        height: 3px;
                        margin: 0px auto;
                        background: #a283f9;
                        border-radius: 3px;
                    }
                }
                &.visit {
                    flex-grow: 1;
                    margin-left: 20px;
                }
                &.black-count {
                    margin-left: 20px;
                }
                > span {
                    color: rgba(255, 255, 255, 0.7);
                }
                &:last-child {
                    margin-right: 52px;
                }
            }
        }
        ul.list {
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            padding-bottom: 50px;

            li + li {
                margin-top: 15px;
            }
            li {
                display: flex;
                padding-right: 35px;

                > img {
                    flex: none;
                    width: 60px;
                    height: 60px;
                    margin-right: 20px;
                    object-fit: cover;
                    border-radius: 50%;
                    cursor: pointer;
                }
                .user-info {
                    width: 0;
                    flex: auto;
                    display: flex;
                    padding-bottom: 20px;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                    &.black-list {
                        align-items: center;
                        padding-bottom: 0;
                    }
                    .nick-box {
                        width: 0;
                        flex: auto;
                        display: flex;
                        flex-direction: column;
                        margin-right: 20px;
                        .nick {
                            display: inline-flex;
                            align-items: center;
                            font-size: 16px;

                            color: #ffffff;
                            cursor: pointer;

                            span:first-child {
                                font-weight: bold;
                            }
                            .gender {
                                margin-left: 10px;
                                font-size: 14px;
                                line-height: 1.4;
                                display: inline-flex;
                                align-items: center;

                                padding: 0 5px;

                                color: #ff6cb8;
                                background: rgba(255, 108, 184, 0.1);
                                border-radius: 4px;
                                img {
                                    .rect(15px);
                                    object-fit: contain;
                                    margin-right: 2px;
                                }
                                &.man {
                                    color: #8ff8ec;
                                    background: rgba(143, 248, 236, 0.1);
                                }
                            }
                            .vip-level {
                                margin-left: 5px;
                            }
                        }
                        .autograph {
                            // width: 0px;
                            .single-line();
                            margin-top: 5px;

                            font-size: 12px;
                            font-weight: 400;
                            color: rgba(255, 255, 255, 0.7);
                        }
                    }
                    .right-box {
                        align-self: center;

                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;

                        .time {
                            font-size: 12px;
                            color: rgba(255, 255, 255, 0.7);
                            margin-bottom: 3px;
                        }
                        .follow-handle {
                            flex: none;
                            .middle();

                            width: 70px;
                            height: 30px;

                            font-size: 16px;
                            line-height: 1.2;
                            color: #b8a0fb;
                            background-color: rgba(184, 160, 251, 0.1);
                            border-radius: 4px;

                            cursor: pointer;
                            user-select: none;
                            &.following,
                            &.removed {
                                background-color: unset;
                                color: rgba(153, 153, 153, 0.4);
                                border: 1px solid rgba(153, 153, 153, 0.4);
                            }
                            &.hide {
                                width: 90px;
                            }
                        }
                    }
                }
            }
        }
        .no-more {
            font-size: 12px;
            color: #999;
        }
        // &::after {
        //     content: "";
        //     position: absolute;
        //     bottom: 0;
        //     left: 0;
        //     background: red;
        //     height: 0px;
        //     width: 100%;
        //     box-shadow: 0px 0px 14px 15px #100e2f;
        // }
    }
    .scale-fade(@speed: 0.3s; @scale:0.6);
}
</style>
