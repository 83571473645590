<script setup>
import { isEmpty, isPhone, getFingerPrint } from "@/utils";
const emit = defineEmits(["goToLogin"]);
const inviteCode = sessionStorage.inviteCode;
const router = useRouter(),
    store = appStore(),
    state = reactive({
        mobile: "",
        password: "",
        code: "",
        invitationCode: inviteCode || "",
        isDisabled: false,
        loading: false,
        phoneMsg: "",
        codeMsg: "",
        signType: 0, //默认验证码登录
        codeText: "获取验证码",
    });
async function register() {
    if (state.loading) return false;
    state.loading = true;
    //注册成功，自动调用登录接口
    const deviceCode = await getFingerPrint();
    try {
        const { code, data, msg } = await fly.postRegister({
            mobile: state.mobile,
            password: state.password,
            code: state.code,
            invitationCode: inviteCode || state.invitationCode,
            deviceCode, //注册设备码
            registerChannel:3,
            deviceType:1
        }); //注册
        if (code === 0) {
            // emit("goToLogin", 1);//跳转登录页
            Toast.success({
                message: "注册成功",
                teleport: "#login-right",
                className: "f-pa",
            });

            const resp = await fly.postLogin({ mobile: state.mobile, password: state.password, deviceCode }); //登录
            if (resp.code === 0 && resp.data) {
                store.setUserInfo(resp.data);
                store.updateUserInfo();
                store.openLoginDialog = false;
            }
        } else if (code === 10090) {
            //设备码被封禁
            Toast.fail({
                message: "该设备已被封禁",
                teleport: "#login-right",
                className: "f-pa",
            });
        } else if (msg) {
            Toast.fail({
                message: msg,
                teleport: "#login-right",
                className: "f-pa",
            });
        }
    } catch (error) {
        console.log("error :>> ", error);
    }
    state.loading = false;
}
function count() {
    let curTime = 60,
        strTime = "",
        timer = setInterval(() => {
            if (curTime > 0) {
                --curTime;
                strTime = String(curTime).padStart(2, "0");
                state.codeText = strTime;
            } else {
                state.codeText = "重新发送";
                state.isDisabled = false;
                clearInterval(timer);
            }
        }, 1000);
}
async function verify() {
    if (isEmpty(state.mobile)) {
        Toast.fail({
            message: "手机号不能为空",
            teleport: "#login-right",
            className: "f-pa",
        });
        state.mobile = "";
        return false;
    } else if (!isPhone(state.mobile)) {
        Toast.fail({
            message: "手机号格式错误",
            teleport: "#login-right",
            className: "f-pa",
        });
        return false;
    }
    // state.isDisabled = true;
    try {
        const { code, msg } = await fly.getSendMsg({
            mobile: state.mobile,
        });
        if (code === 0) {
            count();
            return;
        } else if (msg) {
            Toast.fail({
                message: msg,
                teleport: "#login-right",
                className: "f-pa",
            });
        }
    } catch (error) {
        console.log("error :>> ", error);
    }
}
const signCondition = computed(() => {
    return !state.mobile || !state.code || !state.password;
});
</script>

<template>
    <section class="login-main" id="register-main">
        <nav class="f-fx login-nav">
            <p class="f-csp active">注册</p>
            <div class="gap"></div>
            <p class="back" @click="emit('goToLogin', 0)">返回登录</p>
        </nav>

        <div class="main-content" @keydown.enter="register">
            <a-input placeholder="请输入手机号" maxlength="11" v-model="state.mobile" />
            <a-input placeholder="请输入密码" inputType="password" v-model="state.password" />
            <div class="sign-input f-dfc">
                <input
                    type="text"
                    @input="clearError"
                    @focus="state.isCls = false"
                    maxlength="6"
                    class="sign-code f-fx1 f-cfff"
                    autocomplete="new-password"
                    v-model="state.code"
                    placeholder="请输入验证码"
                />
                <button class="sign-verify f-oun f-bt" :disabled="state.isDisabled" @click="verify">
                    {{ state.codeText }}
                </button>
            </div>
            <a-input v-if="!inviteCode" placeholder="邀请码(非必填)" maxlength="20" v-model="state.invitationCode" @input="state.invitationCode = state.invitationCode.replace(/[^0-9]/g, '')" />
        </div>
        <div class="foot-content">
            <button class="f-w100 f-fs16 login-btn f-csp" @click="register" :disabled="signCondition">{{ state.loading ? "注册中..." : "注册" }}</button>
            <p>
                继续即表示同意
                <a href="https://app.cdddian.com/html/TreatyUsers.html" target="_blank">用户协议</a>
                和
                <a href="https://app.cdddian.com/html/TreatyUsers.html" target="_blank">隐私条款</a>
            </p>
        </div>
    </section>
</template>
<style lang="less" scoped>
#login-right {
    position: relative;
}
.btn:disabled {
    opacity: 0.2;
    cursor: not-allowed;
}
.sign-error {
    margin-bottom: 20px;
}
.sign-close {
    transform: translateY(3px);
}
.sign-code {
    width: 95%;
}
.sign-input {
    background-color: @bg-white01;
}

.login-nav {
    display: flex;
    align-items: flex-end;
    padding: 0 4px;
    .gap {
        flex: auto;
    }
    p {
        line-height: 2;
        color: @color-white03;
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;

        cursor: pointer;
        user-select: none;
        &::after {
            content: "";
            position: relative;
            top: 12px;
            display: none;

            width: 30px;
            height: 3px;
            margin: 0px auto;
            background: linear-gradient(135deg, #a283f9 0%, #7b4dfd 100%);
            border-radius: 2px;
        }
        &.active {
            font-weight: 600;
            color: #a97eff;
            &::after {
                display: block;
            }
        }
        &.back {
            font-size: 14px;
            margin-right: 5px;
            font-weight: 400;
            cursor: pointer;
            user-select: none;
            &:hover {
                color: #a97eff;
            }
        }
    }
}
.register-goback {
    padding-right: 40px;
}

.login-main {
    display: flex;
    flex-direction: column;

    width: 100%;
    min-width: 410px;
    padding: 48px 36px 24px 46px;
    .main-content {
        flex: auto;
        width: 100%;
        margin-top: 50px;
        .sign-input + .sign-input {
            margin-top: 12px;
            .sign-verify {
                color: #a97eff;
                font-weight: 400;

                cursor: pointer;
                user-select: none;
            }
        }
    }
    .foot-content {
        text-align: center;
        .login-btn {
            max-width: 320px;
            height: 58px;

            font-size: 16px;
            color: rgba(255, 255, 255, 0.8);
            background: linear-gradient(135deg, #a283f9 0%, #7b4dfd 100%);
            border-radius: 45px;
            &:disabled {
                color: rgba(255, 255, 255, 0.3);
                cursor: not-allowed;
                background: linear-gradient(135deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);
            }
        }
        > p {
            margin-top: 12px;
            font-size: 14px;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.3);
            a {
                padding: 0 5px;
                color: #a97eff;
                cursor: pointer;
            }
        }
    }
}
</style>
