<script setup>
// import appStore from '@/store'
// const store = appStore()
const attr = useAttrs()
const props = defineProps({
    background:{
        type:String,
        default:''
    }
})
let nicknameColor = ''
let bgColor = ''
const {background} = props
if(background){
    const [start,end] = background.split(';')
    bgColor = `linear-gradient(to bottom, ${start} 0%, ${end} 100%)`
    // console.log('color:====>',bgColor);
}
</script>
<template>
    <p :class="[bgColor ? 'nickname' : '']">
        <slot />
    </p>
</template>
<style lang="less" scoped>
.nickname{
    background: v-bind(bgColor);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>