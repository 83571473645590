<script setup>
import QrcodeVue from "qrcode.vue";
const store = appStore();
const isDev = import.meta.env.DEV;

const list = ref([10, 30, 60, 128, 418, 648, 1000, 2000]);
const customAmount = ref("1");
const isSelected = ref(6);
const isFocus = ref(false);
const financePlatform = ref(1);
const isOpen = ref(false);
const qrcodeUrl = ref(""); //weixin://wxpay/bizpayurl?pr=KiHxEDjzz
const codeLoading = ref(true);
let loading = false
const amount = computed(() => {
    if (parseInt(customAmount.value) <= 0) {
        customAmount.value = 1;
    }
    return (parseInt(customAmount.value) * 1e2) | 0;
});

watch(isOpen, val => {
    if (!val) {
        store.getUserWallet();
    }
});

function tab(val) {
    isSelected.value = val;
    isFocus.value = false;
}
function switchPay(val) {
    financePlatform.value = val;
}
function onBlur() {
    if (!customAmount.value) {
        isFocus.value = false;
    }
}
function onFocus() {
    isFocus.value = true;
    isSelected.value = "";
    customAmount.value = 1;
}
async function pay() {
    let totalMoney = isSelected.value || customAmount.value;
    if (!parseInt(totalMoney)) {
        isSelected.value = 6;
        totalMoney = 6;
    }
    if(loading) return false
    loading = true
    try {
        const { data, code,msg } = await fly.getRecharge({
            totalMoney,
            financePlatform: financePlatform.value,
            terminalType: 3
        });
        if (code === 0 && data) {
            qrcodeUrl.value = data;
            isOpen.value = true;
            // console.log(data);
            // store.updateUserInfo()
        } else {
            Toast.fail(msg||"维护中");
        }
    } catch (error) {
        console.log("error :>> ", error);
    } finally {
        loading = false
    }
}

//关闭弹窗时的回调
function onClose() {
    store.updateUserInfo();
}

//确认我已支付
async function confirmOK() {
    store.getUserWallet;
    isOpen.value = false;

    store.showRechargeDialog = false;
}

//模拟充值
function mockPay() {
    if (import.meta.env.DEV) {
        fly.getMockPay({
            userId: store.userId,
            money: isSelected.value || customAmount.value,
        });
    }
}
//恢复账号首冲初始状态
function mockReturn() {
    if (import.meta.env.DEV) {
        fly.getMockReturn({
            userId: store.userId,
        });
    }
}
</script>
<template>
    <van-dialog v-model:show="store.showRechargeDialog" @close="onClose" class="f-tac recharge-dialog" width="760" :show-confirm-button="false" :closeOnClickOverlay="true" teleport="body">
        <section class="main-recharge">
            <img class="close-btn" src="@/assets/follow/close.png" alt="" @click="store.showRechargeDialog = false" />
            <div class="main-recharge-title">星钻充值</div>
            <div class="balance" v-if="store.userInfo">
                <span>星钻余额</span>
                <img src="@/assets/diamond.png" alt="" />
                {{ store.userInfo.diamond }}
            </div>
            <div class="sub-title">充值金额</div>
            <ul class="main-recharge-list f-dfc">
                <li class="f-dfc" :class="{ active: item === isSelected }" v-for="(item, ind) in list" @click="tab(item)" :key="ind">
                    <p class="f-fx1">{{ item >= 1e3 ? item / 1e2 + "万" : item + "00" }}</p>
                    &ensp;{{ item }}元
                </li>
            </ul>
            <!-- <div class="f-dfc main-recharge-input" :class="[isFocus?'main-recharge-input-active':'']" @click="onFocus">
            <p class="f-fx1">{{amount}}</p><input @blur="onBlur" v-check-number="customAmount" type="tel" min="1" maxlength="6" placeholder="请输入" class="f-bt f-tar" v-model.trim="customAmount"/>
        </div> -->
            <h3 class="sub-title">支付方式</h3>
            <div class="main-recharge-paytype f-dfc">
                <span class="main-recharge-weixin f-dfc f-csp" :class="{ active: financePlatform === 1 }" @click="switchPay(1)">微信</span>
                <span class="main-recharge-alipay f-dfc f-csp" :class="{ active: financePlatform === 0 }" @click="switchPay(0)">支付宝</span>
            </div>
            <p class="pay f-tac f-csp" @click="pay">立即支付</p>

            <van-dialog
                v-model:show="isOpen"
                class="recharge-dialog f-tac"
                width="350"
                :show-confirm-button="false"
                :closeOnClickOverlay="true"
                teleport="body"
                :overlayStyle="{ background: 'rgba(0, 0, 0,.45)' }"
            >
                <h3>请使用{{ ["支付宝", "微信"][financePlatform] }}扫码</h3>
                <div class="qrcode-wrap">
                    <QrcodeVue :value="qrcodeUrl" :margin="2" :size="160" level="H" />
                </div>
                <!-- <template v-if="isDev">
                    <button class="btn confirm-btn" @click="mockPay">测试模拟充值</button>
                    <button class="btn confirm-btn" @click="mockReturn">测试恢复首冲状态</button>
                </template> -->
                <button class="btn confirm-btn" @click="confirmOK">确认我已支付</button>
            </van-dialog>
        </section>
    </van-dialog>
</template>
<style lang="less" scoped>
.main-recharge {
    text-align: left;
    padding: 20px 30px 30px;
    color: #ffffff;
    h3 {
        font-weight: 200;
    }
    .close-btn {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 25px;
        padding: 5px;
        cursor: pointer;
    }
    .main-recharge-title {
        font-size: 16px;
    }
    .balance {
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        margin-top: 20px;

        span {
            color: rgba(255, 255, 255, 0.4);
            margin-right: 15px;
        }
        img {
            width: 18px;
            height: 14px;
            margin-right: 2px;
        }
    }
    .sub-title {
        margin-top: 15px;
        margin-bottom: 10px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.4);
    }
}
.main-recharge-list {
    flex-wrap: wrap;
}

.main-recharge-list li {
    width: 168px;
}
.main-recharge-list li,
.main-recharge-input {
    height: 52px;
    line-height: 52px;
    background: rgba(255, 255, 255, 0.07);

    border-radius: 16px;
    margin: 0 24px 20px 0;
    padding: 0 15px;
    &:hover {
        background: rgba(255, 255, 255, 0.09);
    }
    cursor: pointer;
    &::before {
        content: "";
        width: 18px;
        height: 14px;
        margin-right: 5px;
        display: inline-block;
        background: url(@/assets/diamond.png) no-repeat center center/100%;
    }
}

.main-recharge-list li.active {
    background: url(@/assets/re-icon0.png) no-repeat center center/100%;
}
.main-recharge-input-active {
    background: url(@/assets/re-icon1.png) no-repeat center center/100% !important;
}
.main-recharge-input {
    max-width: 192px;
    input {
        width: 68px;
        height: 20px;
        border: 1px solid;
        border-color: transparent transparent #7b4dfd transparent;
        border-radius: 1px;
    }
}
.main-recharge-weixin {
    background: url(@/assets/re-icon-weixin0.png) no-repeat center center/100%;
}
.main-recharge-alipay {
    background: url(@/assets/re-icon-alipay0.png) no-repeat center center/100%;
}
.main-recharge-paytype {
    span {
        width: 168px;
        height: 52px;
        padding-left: 90px;
        margin: 0px 24px 5px 0px;
        border-radius: 16px;
        &.main-recharge-weixin.active {
            background: url(@/assets/re-icon-weixin1.png) no-repeat center center/100%;
        }
        &.main-recharge-alipay.active {
            background: url(@/assets/re-icon-alipay1.png) no-repeat center center/100%;
        }
        &:hover {
            background-color: rgba(255, 255, 255, 0.02);
        }
    }
}
.pay {
    width: 260px;
    height: 50px;
    line-height: 50px;
    margin: 20px auto 0px;
    background: linear-gradient(135deg, #a283f9 0%, #7b4dfd 100%);
    border-radius: 25px;
    user-select: none;
    &:hover {
        background: linear-gradient(135deg, lighten(#a283f9, 2%) 0%, lighten(#7b4dfd, 2%) 100%);
    }
}
.qrcode-wrap {
    margin-bottom: 20px;
}
.confirm-btn {
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    margin-bottom: 20px;
    padding: 0 30px;
    border-radius: 20px;
    cursor: pointer;
    background: linear-gradient(135deg, #a283f9 0%, #7b4dfd 100%);
    &:hover {
        background: linear-gradient(135deg, lighten(#a283f9, 2%) 0%, lighten(#7b4dfd, 2%) 100%);
    }
}
</style>
<style>
.recharge-dialog {
    background: linear-gradient(141deg, #150d3b 0%, #05071c 100%);
    box-shadow: 0px 2px 12px 0px rgba(5, 7, 28, 0.7);
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    /* border-image: linear-gradient(135deg, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.1)) 1 1; */
    color: #ffffff;
}
</style>
