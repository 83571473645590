import { throttle } from "@/utils";
export default app => {
    app.directive("focus", {
        mounted(el, binding) {
            el.focus();
        },
    });
    //用于校验数字输入框
    app.directive("check-number-input", {
        mounted(el, binding) {
            el.addEventListener("input", event => {
                const { target } = event;
                if (parseInt(target.value) <= 0 || !/(^[1-9]\d*$)/.test(target.value)) {
                    binding.value("");
                }
            });
        },
    });
    app.directive("check-number", {
        //用于校验数字输入框
        mounted(el, binding) {
            el.addEventListener("input", ({ target }) => {
                // console.log(typeof target.value);
                if (parseInt(target.value) <= 0 || !/(^[1-9]\d*$)/.test(target.value)) {
                    //正整数
                    target.value = 1;
                }
                // console.log(target.value);
                binding.value = target.value;
            });
        },
    });
    app.directive("drag", {
        mounted(el) {
            el.onmousedown = ev => {
                // 鼠标按下的位置
                const mouseXStart = ev.clientX;
                const mouseYStart = ev.clientY;
                // console.log("按下开始", mouseXStart, mouseYStart);
                // 当前滑块位置
                const rectLeft = el.offsetLeft;
                const rectTop = el.offsetTop;
                const rectWidth = el.offsetWidth;
                const rectHeight = el.offsetHeight;
                document.onmousemove = e => {
                    // 鼠标移动的位置
                    const mouseXEnd = e.clientX;
                    const mouseYEnd = e.clientY;
                    let moveX = mouseXEnd - mouseXStart + rectLeft;
                    let moveY = mouseYEnd - mouseYStart + rectTop;
                    if (moveX < 0) {
                        moveX = 0;
                    } else if (moveX > innerWidth - rectWidth) {
                        moveX = innerWidth - rectWidth;
                    }
                    if (moveY < 0) {
                        moveY = 0;
                    } else if (moveY > innerHeight - rectHeight) {
                        moveY = innerHeight - rectHeight;
                    }
                    // console.log(rectLeft, rectTop);
                    el.style["top"] = moveY + "px";
                    el.style["left"] = moveX + "px";
                };
                document.onmouseup = () => {
                    // console.log("鼠标抬起");
                    // 取消事件
                    document.onmousemove = null;
                };
            };
        },
    });
    //点击拖动 //1.可以分开设置移动的元素和点击的元素 2.可以移出窗口外
    app.directive("drag-window", {
        mounted(moveEl) {
            let clickElArr = moveEl.querySelectorAll(".drag");
            if (!clickElArr.length) clickElArr = [moveEl];
            let x = 0;
            let y = 0;
            let l = 0;
            let t = 0;
            //计算移动后的偏移量
            function onmousemoveEvent(e) {
                let nl = e.clientX - (x - l);
                let nt = e.clientY - (y - t);
                moveEl.style.left = nl + "px";
                moveEl.style.top = nt + "px";
            }
            clickElArr.forEach(el => {
                //鼠标按下事件
                el.onmousedown = function (e) {
                    // e.stopPropagation();//阻止冒泡
                    e.preventDefault(); //取消默认事件
                    x = e.clientX; //获取鼠标点击的 x坐标 和 y坐标
                    y = e.clientY;
                    l = moveEl.offsetLeft; //获取左部和顶部的偏移量
                    t = moveEl.offsetTop;
                    window.addEventListener("mousemove", onmousemoveEvent); //监听鼠标移动事件
                };
                //鼠标抬起事件
                el.onmouseup = function (e) {
                    window.removeEventListener("mousemove", onmousemoveEvent); //取消监听
                };
            });
        },
    });

    //监听元素滚动到顶部或底部触发事件scrollbottom、scrolltop
    app.directive("scroll", {
        mounted(el) {
            const scrollbottom = new Event("scrollbottom", { bubbles: false, cancelable: false });
            const scrolltop = new Event("scrolltop", { bubbles: false, cancelable: false });
            const fuc = throttle(() => {
                //当滚动到底部或顶部
                if (el.clientHeight !== el.scrollHeight) {
                    if (el.scrollTop === 0) {
                        el.dispatchEvent(scrolltop);
                    } else if (el.scrollTop + 1 > el.scrollHeight - el.clientHeight) {
                        el.dispatchEvent(scrollbottom);
                    }
                }
            }, 100);
            //监听滚动
            el.addEventListener("scroll", fuc);
        },
    });
    //监听元素滚动到左右触发事件
    app.directive("scroll-left-right", {
        updated(el) {
            // console.log("123124213 :>> ", 123124213);
        },
        mounted(el) {
            const scrollleft = new Event("scrollleft", { bubbles: false, cancelable: false });
            const scrollright = new Event("scrollright", { bubbles: false, cancelable: false });
            const fuc = throttle(() => {
                console.log("123124213 :>> ", 123);

                //当滚动到底部或顶部
                if (el.clientWidth !== el.scrollWidth) {
                    if (el.scrollTop === 0) {
                        el.dispatchEvent(scrollleft);
                        console.log("1 :>> ", 1);
                    } else if (el.scrollTop + 1 > el.scrollWidth - el.clientWidth) {
                        el.dispatchEvent(scrollright);
                        console.log("2 :>> ", 2);
                    }
                }
            }, 300);
            //监听滚动
            el.addEventListener("scroll", fuc);
        },
    });
};
