<template>
<van-dialog width="1020" class="service-box" v-model:show="store.openKfDialog" :show-confirm-button="false" :closeOnClickOverlay="true" teleport="body">
    <div class="TUIKit kf-container">
      <TUIChat>
        <template #default>
          <van-icon name="cross" size="22" color="#fff" class="f-csp" @click="store.openKfDialog=false"/>
        </template>
      </TUIChat>
    </div>
</van-dialog>
</template>
<script setup>
import { TUIChat } from "@/TUIKit";
const store = appStore();
//   function openGroupChat() {
//     // 切换会话进入聊天
//     TUIConversationService.switchConversation(`GROUP${groupID}`);
//   }
</script>
<style scoped lang="scss">
  .chat {
    flex: 1;
  }
  :deep(.message-bubble .message-bubble-main-content .message-body .message-body-main .content-in),
  :deep(.message-bubble .message-bubble-main-content .message-body .message-body-main .content-out),
  :deep(.scroll-button){
    background: rgba(255, 255, 255, 0.1);
  }
  :deep(.message-bubble .message-bubble-main-content .message-body .message-body-main .content-in),
  :deep(.message-bubble .message-bubble-main-content .message-body .message-body-main .content-out),
  :deep(.branchCard .branchItem),
  :deep(.chat-header-content),
  :deep(.scroll-button-text),
  :deep(.btn-cancel),
  :deep(.dialog-main-content),
  :deep(.message-input-button-cont){
    color: rgba(255, 255, 255, 0.9);
  }
  :deep(.message-input-button-cont),
  :deep(.submitButton),
  :deep(.ratingCard button:disabled),
  :deep(.btn-default){
    background: linear-gradient(90deg, #7B4DFD, #A283F9);
  }
  :deep(.scroll-button),:deep(.dialog-item-web){
    border-color: transparent;
  }
  :deep(.message-input-toolbar){
    border-color: rgba(255, 255, 255, 0.1);
  }
  :deep(.toolbar-item-container-dialog),
  :deep(.dialog-item-web),
  :deep(.dialog-main){
    background-color: rgb(38, 35, 53);
  }
  :deep(.btn-cancel){
    background: #929292;
  }
  :deep(.btn){
    height: 40px;
  }
  :deep(.ratingCard){
    background-color: transparent;
  }
  :deep(.ratingCard button:disabled){
    opacity: .3;
  }
  .kf-container{
    width:1020px;
    height: 670px;
    background: linear-gradient(141deg, #150d3b 0%, #05071c 100%);
    color: rgba(255, 255, 255, 0.4);
  }
</style>