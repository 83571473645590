<script setup>
import uPlaceImg from "@/assets/ubg.jpg";
const router = useRouter();
const store = appStore();
const isSelected = ref(false);
const isSearch = ref(false);
const searchUserList = ref([]);
const searchChatRoomList = ref([]);
const searchKeyword = ref("");
const selectType = ref(0);
const isOpen = ref(false);
const followLoading = ref(false); //关注loading
const photoAlbum = computed(() => {
    return store.profile.userInfo.photoAlbumList[0] ? store.profile.userInfo.photoAlbumList : [uPlaceImg];
});
const selectText = computed(() => {
    return ["房间ID", "用户ID"][selectType.value];
});
function select() {
    // isSelected.value = !isSelected.value
    isSearch.value = false;
}
function onClose() {
    // isSelected.value = false
    isSearch.value = false;
}
function onSelect(type) {
    selectType.value = type;
}
async function onSubmit(val) {
    if (val) {
        searchKeyword.value = val;
    }
    if (!searchKeyword.value) {
        return false;
    }
    const {
        data: { userList, chatRoomList },
    } = await fly.getGlobalSearch({ pageSize: 10, currentPage: 1, content: searchKeyword.value });
    // console.log(`submit...${searchKeyword.value}`);

    // if (userList[0]) {
    //     await fly.getUserStatus({ userId: userList[0].id }); //查询用户是否被封禁
    //     store.setSearchHistory(userList[0].id);
    //     searchUserList.value = userList;
    // }
    // if (chatRoomList[0]) {
    //     store.setSearchHistory(chatRoomList[0].roomid);
    //     searchChatRoomList.value = chatRoomList;
    // }
    if (Array.isArray(userList)) {
        searchUserList.value = userList;
    }
    if (Array.isArray(chatRoomList)) {
        searchChatRoomList.value = chatRoomList;
    }
    store.setSearchHistory(searchKeyword.value);

    // searchKeyword.value = ''
    if (!val) isSearch.value = true;
}

function navigate(accompanyId, type) {
    if (!store.token) {
        store.openLoginDialog = true;
        return false;
    }
    if (!accompanyId) {
        console.error("No Accompany Id");
        return;
    }
    if (type) {
        if (accompanyId && store.currentRoomID !== accompanyId) {
            const { href } = router.resolve(`/chat-room/${accompanyId}`);
            window.open(href, "_self");
        }
    } else {
        store.previewUserInfo(accompanyId);
    }
    onClose();
}

function openDeleteDialog() {
    Dialog.confirm({
        title: "提示",
        message: "是否确定删除搜索历史？",
        confirmButtonText: "删除",
        className: "confirm-dialog",
        closeOnClickOverlay: true,
        lockScroll: false,
        teleport: "body",
    })
        .then(store.delSearchHistory)
        .catch(() => {});
    return;
}

//关注|取消关注
async function toFollow(userInfo) {
    if (followLoading.value) return;
    followLoading.value = true;

    try {
        const { isFans, uid } = userInfo;
        let resp;
        if (!isFans) {
            //关注
            resp = await fly.getFollow({
                followUserId: uid,
            });
            if (resp && resp.code === 0) {
                if (resp.msg) Toast.success(resp.msg);
                userInfo.isFans = 1;
            }
        } else {
            //取消关注
            resp = await fly.getUnFollow({
                followUserId: uid,
            });
            if (resp && resp.code === 0) {
                if (resp.msg) Toast.success("已取消关注");
                userInfo.isFans = 0;
            }
        }
    } catch (error) {
        console.log("error :>> ", error);
    }
    followLoading.value = false;
}

//跳转聊天
function toChat(userInfo) {
    const { uid } = userInfo;
    if (!uid) return;
    store.$patch(state => {
        state.profile.isOpen = false;
    });
    //打开聊天
    store.openChatDialog({
        conversationID: "C2C" + uid,
    });
}
</script>
<template>
    <div class="head-search f-dfc f-csp f-pr">
        <!-- <div class="f-pr head-select">
            <p @click.stop="select">{{ selectText }}&ensp;<van-icon :name="`arrow-${isSelected?'up':'down'}`" color="#ccc"/>&ensp;</p>
            <ul class="f-pa head-select-ul" v-show="isSelected">
                <li @click="onSelect(0)">房间ID</li>
                <li @click="onSelect(1)">用户ID</li>
            </ul>
        </div> -->
        <img src="@/assets/icon-search.png" class="head-search-icon" @click="() => onSubmit()" />
        <input type="text" v-model.trim="searchKeyword" :placeholder="`请输入${selectText}搜索，按Enter确定`" @keydown.enter="() => onSubmit()" class="head-input f-fs12 f-fx1" />
        <img src="@/assets/icon-x.png" @click="searchKeyword = ''" class="head-search-close" v-show="searchKeyword" />
        <section class="head-select-result f-w100 f-pa no-scrollbar" v-show="isSearch" v-click-outside="onClose">
            <!-- <li v-for="(item,i) in searchList" :key="i" @click.stop="link(`/chat-room/${item}`)" class="f-fx f-aic">
                <img src="https://fakeimg.pl/50x50" alt="">
                <p>xxx</p>
            </li> -->
            <template v-if="store.searchHistory[0]">
                <h3 class="f-fs14 head-search-del f-dfc" @click="openDeleteDialog">
                    <span class="history-del f-fx1">历史</span>
                </h3>
                <ul class="head-search-history f-fx f-fs14">
                    <li class="f-fx f-fc" v-for="item in store.searchHistory" :key="item" @click="onSubmit(item)">{{ item }}</li>
                </ul>
            </template>
            <template v-if="searchChatRoomList[0]">
                <h3 class="f-fs14">房间</h3>
                <ul class="head-search-user f-fx f-fs14">
                    <li v-for="(item, i) in searchChatRoomList" :key="i" @click="navigate(item.roomid, 1)" class="f-dfc f-fx1">
                        <img :src="item.cover" alt="" />
                        <p class="f-thd">{{ item.theme }}</p>
                    </li>
                </ul>
            </template>
            <template v-if="searchUserList[0]">
                <h3 class="f-fs14">用户</h3>
                <ul class="head-search-user f-fx f-fs14">
                    <li v-for="(item, i) in searchUserList" :key="i" @click="navigate(item.id, 0)" class="f-dfc f-fx1">
                        <img :src="item.headPortrait" alt="" />
                        <p class="f-thd">{{ item.nickname }}</p>
                    </li>
                </ul>
            </template>
        </section>
    </div>
    <!-- <van-dialog class="f-cfff input-pwd-dialog f-tac" v-model:show="isOpen" title="提示" :show-confirm-button="false" :closeOnClickOverlay="false" teleport="body">
        <p class="f-mtb20">是否确定删除搜索历史？</p>
        <div class="f-fx input-pwd-wrap">
            <button class="input-pwd-btn cancel-pwd-btn f-fx1 f-csp" @click="isOpen = false">取消</button>
            <button class="input-pwd-btn confirm-pwd-btn f-fx1 f-csp" @click="store.delSearchHistory">确定</button>
        </div>
    </van-dialog>-->
</template>
<style lang="less" scoped>
.head-search {
    border-radius: 15px;
    width: 300px;
    padding: 7px 14px;
    height: 30px;
    line-height: 30px;
    cursor: auto;
    /* border: 1px solid #ccc; */
    background-color: @bg-white01;
    // &::before{
    //     content: '';
    //     display: inline-block;
    //     width: 19px;
    //     height: 17px;
    //     background: url(@/assets/icon-search.png) no-repeat center center/100%;
    // }
    .head-search-icon {
        width: 20px;
        padding: 2px;
        opacity: 0.5;
        cursor: pointer;
    }
}
.head-search-close {
    padding: 5px;
    cursor: pointer;
}
.head-search-del {
    padding: 5px;
    pointer-events: none;
    &::after {
        content: "";
        display: inline-block;
        width: 14px;
        height: 16px;
        background: url(@/assets/icon-del.png) no-repeat center center/100%;
        pointer-events: auto;
    }
    cursor: pointer;
}

.head-select-ul {
    z-index: 1;
    top: 40px;
    left: -11px;
    width: 100px;
    padding-left: 20px;
    background-color: @bg-white01;
    /* border: 1px solid; */
    /* border-color: transparent #eee #eee #eee; */
    border-radius: 5px;
}
.head-select-result {
    z-index: 1;
    top: 40px;
    left: 0;
    // max-height: 300px;
    overflow-y: scroll;
    /* background-color: #aaa;
    border: 1px solid;
    border-color: transparent #eee #eee #eee;
    border-radius: 5px; */
    padding: 12px 16px;
    width: 432px;
    height: 420px;
    background: linear-gradient(141deg, #150d3b 0%, #05071c 100%);
    box-shadow: 0px 2px 12px 0px rgba(5, 7, 28, 0.7);
    border-radius: 8px;
    border: 1px solid;
    border-image: linear-gradient(135deg, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.1)) 1 1;
    h3 {
        margin: 5px 0;
        font-weight: 200;
    }
}
.head-search-user {
    flex-wrap: wrap;
    // justify-content: space-around;
}
.head-search-user li {
    margin: 8px 16px;
    p {
        width: 100px;
    }
}
.head-search-history {
    flex-wrap: wrap;
    li{
        overflow: hidden;
    }
}
.head-search-history li {
    width: 67px;
    height: 23px;
    color: #a283f9;
    background: #1f1647;
    border-radius: 4px;
    margin: 0 6px 6px 0;
    cursor: pointer;
}
.head-search-user li img {
    width: 42px;
    height: 42px;
    border-radius: 22px;
    margin-right: 10px;
}
.head-input {
    color: rgba(255, 255, 255, 0.3);
    border: none;
    background-color: transparent;
    font-size: 14px;
}
.home-user-info {
    .home-user-head {
        .follow {
            text-align: center;
            width: 70px;
            padding: 0 10px;

            color: #b8a0fb;
            background-color: rgba(184, 160, 251, 0.1);
            font-size: 14px;
            white-space: nowrap;

            border-radius: 4px;

            cursor: pointer;
            user-select: none;
            &.following {
                color: rgba(153, 153, 153, 0.7);
                border: 1px solid rgba(153, 153, 153, 0.7);
                background-color: rgba(0, 0, 0, 0);
            }
        }
    }
}
</style>
