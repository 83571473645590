<script setup>
const store = appStore();
const props = defineProps({
    modelValue: Boolean,
});
function agree(){
    switch(store.agreePlayNotice.type){
        case 1:
            store.agreePlayNotice.play=true
            sessionStorage.playNotice = 1
            break;
        case 2:
            store.agreePlayNotice.blindBox=true
            sessionStorage.blindBoxNotice = 1
            break;
        case 3:
            store.agreePlayNotice.activity=true
            sessionStorage.activityNotice = 1
    }
}
// const show = ref(false);
// watch(()=>props.modelValue,(val) => {
//     show.value = val
// });
</script>
<template>
    <van-dialog class="play-notice" :show="modelValue" @update:show="val => $emit('update:modelValue', val)"
        :show-confirm-button="false" :closeOnClickOverlay="false" :show-cancel-button="false" teleport="body">
        <template #title>
            <img src="@/assets/chat-room/play-notice-title.png" class="f-db f-mga dialog-title">
        </template>
        <div class="play-notice-content" v-pre>
            <br>1、通过概率获得的随机礼物仅限于在平台内消费，不得以任何形式兑换成法定货币、现金或其他任何具有交换价值的物品或服务，禁止房主、主持、用户及其他第三方主体进行任何形式的礼物交易，否则平台将严格按照平台规则对相关用户采取相关管理措施，用户应自行对此承担法律责任及相关损失。请用户谨防上当受骗。
            <br>2、本功能玩法不向未成年人开放，未成年人用户请勿参与，平台将通过大数据和实名认证等方式实时跟踪，确保此功能不会被末成年人使用。房间内房主、主持以及房间其他用户禁止引导未成年人用户参与本功能玩法，引导成年人参与本功能玩法将受到平台严厉打击和处罚。
            <br>3、概率玩法是提升房间内互动体验的功能，仅供娱乐交流使用。房主、主持、用户及其他任何主体均不得与任何非法目的与方式(包括但不限于赌博、诈骗等)对其进行使用。
            <br>4、禁止使用概率玩法实施任何影响互动公平性的行为，或利用产品BUG等不正当手法参与互动，一旦发生上述情况，平台有权取消发放对应奖励，追回奖励，对情节严重的，平台保留一切追究法律责任的权利。
            <br>5、用户不得以任何不正当手段或舞弊的方式参与本功能玩法，一经发现，平台有权取消用户的参与资格，并有权收回用户获取的礼物及权益，同时保留追究其相关法律责任的权利。不正当手段及舞弊行为包括但不限于:下载非官方客户端、使用插件、外挂等非法工具扫码、下载、安装、注册、登录、使用;篡改设备数据;恶意牟利等扰乱平台秩序，使用插件、外挂、系统或第三方工具对本平台及本活动进行干扰、破坏、修改或施加其他影响及平台认为的其他不正当手段。
            <br>6、因用户操作不当或用户所在地区网络故障、支付平台网络故障、电信运营商故障、第三方其他平台限制等非平台所能控制的原因导致的用户无法参与活动、或参与失败，平台无需为此承担任何法律责任。
            <br>7、平台可能在房间、功能页展示用户账号信息、支付平台网络故障、电信运营商故障、第三方其他平台限制等非平台所能控制的原因导致的用户无法参与活动、或参与失败，平台无需为此承担任何法律责任。
            <br>8、平台可能在房间、功能页展示用户账号信息及所获得的随机礼物等信息，您授权并同意平台为此使用并展示您的公开帐号信息(如头像、昵称等)及所获得随机礼物等信息。
            <br>9、平台依法运营此功能玩法，如因不可抗力、情势变更，相关政策变动、政府机关指令要求等原因导致本功能玩法调整、暂停举办或无法进行的，平台有权随时决定修改、暂停、取消或终止本功能玩法，并无需为此承担任何法律责任。
        </div>
        <template #footer>
            <div class="f-fx">
                <button class="dialog-btn f-csp" @click="$emit('update:modelValue', false)">取消</button>
                <button class="dialog-btn btn-agree f-cfff f-csp" @click="$emit('update:modelValue', false);agree()">同意</button>
            </div>
        </template>
    </van-dialog>
</template>
<style lang="less" scoped>
.play-notice-content {
    padding: 0 40px;
    font-size: 14px;
    text-align: justify;
    color: white;
    height: 570px;
    overflow-y: scroll;
}
.dialog-title{
    padding-bottom: 20px;
}
.dialog-btn {
    width: 150px;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    margin: 20px auto;
}
.btn-agree {
    background: linear-gradient(90deg, #a283f9, #7b4dfd);
}
</style>
<style lang="less">
.play-notice {
    &.van-dialog {
        width: 560px;
        // border-radius: 8px;
        background: url(@/assets/chat-room/play-notice-bg.png) no-repeat top center/100%;
        // z-index: 91 !important;
    }
}
</style>
