<script setup>
const props = defineProps({
    placeholder: {
        type: String,
        default: "",
    },
    inputType: {
        type: String,
        default: "tel",
    },
    maxlength: {
        type: String,
        default: "",
    },
    modelValue: {
        type: String,
        default: "",
    },
});
const emit = defineEmits(["update:modelValue"]);

const isCls = ref(false);
const inputEl = ref(null);
// {ctx} = getCurrentInstance(),
function changFocus(event) {
    event.preventDefault();
    event.stopPropagation();
    emit("update:modelValue", "");
}
</script>
<template>
    <p class="sign-input f-dfc">
        <input
            :type="props.inputType"
            @input="e => emit('update:modelValue', e.target.value)"
            :maxlength="maxlength"
            @blur="isCls = false"
            @focus="isCls = true"
            class="f-w60 f-cfff"
            ref="inputEl"
            :value="modelValue"
            autocomplete="off"
            :placeholder="props.placeholder"
        />
        <span class="f-fx1 f-tar">
            <img src="@/assets/close.png" @mousedown="changFocus" v-if="isCls && modelValue" class="sign-close" />
        </span>
    </p>
</template>
<style lang="less" scoped>
//去掉chrome浏览器自动输入的背景样式
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition-delay: 99999s;
    transition: color 99999s ease-in-out 0s, background-color 99999s ease-in-out 0s;
}

.sign-input {
    color: @color-black;
    input[type="password"]::-ms-reveal {
        display: none;
    }
}
.sign-close {
    transform: translateY(3px);
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 0.2s ease;
    &:hover {
        opacity: 0.7;
    }
}
</style>
