import flyio from "flyio";
import config from "./config";
import { getUrl } from "../utils";
// const mitts = mitt()
const { VITE_sPath, VITE_debug, VITE_TOKEN } = import.meta.env;
flyio.config.baseURL = VITE_sPath; //"http://gl.cdddian.cn/zsc"
flyio.interceptors.request.use(
    config => {
        const token = localStorage.getItem("token") || ""; //VITE_TOKEN
        // console.log(token);
        config.headers.channelId = 1
        config.headers.terminalType = 3
        config.headers.registerChannel = 3
        config.headers.deviceType = 1
        if (token) {
            config.headers.token = token; //请求头带上token
        }
        if(VITE_debug==1){
            // console.log( "请求参数:---->", config);
        }
        // if (config.method === "post") {
            // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
            // config.data = qs.stringify(config.data)
        // }
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject(error);
    }
);
flyio.interceptors.response.use(
    ({ data, request }) => {
        // if(VITE_debug==1){
        //     console.log("路由:--->", request.url, "\n", "响应参数:---->", data);
        // }
        if (data) {
            //SELF_CATCH_ERROR传true时，此处不处理错误  //用于不展示接口返回的错误信息，调用接口的地方单独处理错误
            const errorCode = [10011,10038,10035,10018,10055,100091,100017,100018,100052,100144]
            if (!request.body || !request.body.SELF_CATCH_ERROR) {
                if (errorCode.includes(data.code)) {
                    Toast.fail(data.msg||'Error');
                    return Promise.reject(data);
                }
            }
            if (data.code === 401 || data.code === 417) {
                //token过期
                const store = appStore();
                store.logout();
                location.href = "/";
                return Promise.reject(data);
            }
        }
        return data;
    },
    error => {
        console.log(error);
        return Promise.reject(error);
    }
);
class API {
    constructor() {
        config.api.forEach(({ type, url, fnName }) => {
            this[fnName] = (data, options) => this[type](url, data, options);
        });
    }
    async get(url, params = {}, option) {
        if (Array.isArray(url)) {
            const all = url.map(async u => await fly.get(u, params, option));
            return flyio.all(all);
        } else {
            return await flyio.get(url, params, option);
        }
    }
    async put(url, data = {}, option) {
        if(data.margeParams){//特殊处理：合并参数
            const urlParams = getUrl(url,data);
            return await flyio.put(urlParams, option);
        }
        return await flyio.put(url, data, option);
    }
    async post(url, data = {}, option) {
        if (Array.isArray(url)) {
            const all = url.map(async u => await flyio.post(u, data, option));
            return flyio.all(all);
        } else {
            return await flyio.post(url, data, option); //`${url}?v=${this.timestamp}`
        }
    }
}
// export const emitter = mitts
export const Http = API;
export const fly = new API();
export default {}
