<script setup>
let intersectionObserver = null
const loadTip = ref(null)
const props = defineProps({
    handleIntersect: Function,
    rootSelector: String,
    noMore: Boolean,
    color: {
        type:String,
        default:''
    },
    isShowTip: Boolean
})
onMounted(()=>{
    intersectionObserver = new IntersectionObserver(([entry])=>{
        if (entry && entry.isIntersecting){//entry.intersectionRatio <= 0
            // return false
            props.handleIntersect()
            console.log('Loaded new items');
        }
        // console.log('checking...');
    },{
        root: document.querySelector(`#${props.rootSelector}`),
        threshold:[0.000001]
    })
    intersectionObserver.observe(loadTip.value)
    // console.log('testing...',props.noMore);
})
onBeforeUnmount(()=>{
    intersectionObserver.disconnect()
})
</script>
<template>
    <div ref="loadTip" class="f-fx f-fc load-tip" :style="{color}">
        <template v-if="noMore">
            <i v-if="isShowTip">没有更多了</i>
        </template>
        <template v-else>
            <van-loading size="24"/>&ensp;加载中...
        </template>
    </div>
</template>