<script setup>
import {emojiBaseUrl,emojiUrlMapping,transformEmojiValueToKey} from "@/utils/emoji"
// import emojiList from "@/assets/emoji";
const props = defineProps({
    text: String,
    other: Object,//其他信息
});
const reg = /(\[.+?\])/g
const textArr = computed(() => {
    const textContent = props.text
    if(!textContent) return []
    if(props.other && props.other.mType == 'system'){
        return [{text:textContent}]
    }
    const out = textContent.split(reg).map(el=>{
        if(el){
            const emoji = transformEmojiValueToKey(el)
            return emoji.endsWith('.png') ? {
                img:`${emojiBaseUrl}${emoji}`
            } : {
                text:el,
                status: [true,false,false][props.other?.status],
            }
        }
    }).filter(Boolean)
    // console.log('文本内容：',out);
    return out
});
const handler = {
    //加入公会
    guildInvitesToJoin:async (agreeOrReject) => {
        await fly.postAnchorJoinGuild({
            unionId: props.other.unionId,
            agreeOrReject
        })
    },
    //加入合作
    guildUserTypeToCooperation:async (agreeOrReject) => {
        await fly.postGuildCooperationInvite({
            unionId: props.other.unionId,
            agreeOrReject
        })
    },
    //加入特邀
    guildUserTypeToSpecial:async (agreeOrReject) => {
        await fly.postAnchorJoinSpecialInvite({
            unionId: props.other.unionId,
            agreeOrReject
        })
    },
}
async function onHandler(agreeOrReject){
    try {
        await handler[props.other.mType](agreeOrReject)
        if(agreeOrReject==1){
            Toast.success('签约成功!');
        }
    } catch (error) {
        console.error(error);
    }
}
</script>
<template>
    <template v-for="(item, index) in textArr" :key="index">
        <div v-html="item.text" class="msg-text-wrap"></div>
        <div class="msg-btn-wrap f-w100 f-fx" v-if="item.status">
            <button class="f-db msg-btn btn-reject f-csp" @click="onHandler(2)">拒绝</button>
            <button class="f-db msg-btn btn-agree f-csp" @click="onHandler(1)">同意</button>
        </div>
        <img v-if="item.img" :src="item.img"/>
        <!-- <ChatBubble> -->
            <!-- <div v-if="!item.isEmoji" v-html="item.text"></div>
            <img v-else :src="`${emojiBaseUrl}/${item.text}`" :alt="item.text" />
        </ChatBubble> -->
    </template>
</template>
<style lang="less">
.msg-text-wrap{
    // min-width: 450px;
    line-height: 1.6;
    h3{
        margin:0 0 10px;
    }
    h4{
        margin: 0;
    }
}
</style>
<style lang="less" scoped>
img {
    width: 20px;
    height: 20px;
}
.msg-btn-wrap{
    justify-content: flex-end;
}
.msg-btn {
    width: 100px;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    margin:10px 5px;
    // margin: 20px auto;
}
.btn-reject{
    color: #7b4dfd;
}
.btn-agree {
    background: linear-gradient(90deg, #a283f9, #7b4dfd);
}
</style>
