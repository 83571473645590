<script setup>
const props = defineProps({
    voice: Object,
});

const voiceUrl = computed(() => (props.voice && props.voice.voice) || "");
const voiceDuration = computed(() => (props.voice && props.voice.voiceDuration) || "");
const playing = ref(false);

onBeforeUnmount(() => {
    if (playing.value) {
        getAudioEl().pause();
    }
});

//播放或暂停
function playVoice() {
    const audioEl = getAudioEl();
    if (!audioEl) return;
    if (playing.value) {
        return audioEl.pause();
    }
    audioEl.onplay = () => {
        playing.value = true;
        //监听暂停或新的播放
        function stop() {
            playing.value = false;
            audioEl.removeEventListener("pause", stop);
            audioEl.removeEventListener("play", stop);
        }
        audioEl.addEventListener("pause", stop);
        audioEl.addEventListener("play", stop);
        audioEl.onplay = null;
    };
    audioEl.src = voiceUrl.value;
    audioEl.play();
}

//获取或创建Audio标签
function getAudioEl() {
    let audioEl = document.querySelector("#voice-play");
    if (!audioEl) {
        audioEl = document.createElement("audio");
        audioEl.id = "voice-play";
        // document.body.appendChild(audioEl)
        document.body.insertBefore(audioEl, document.body.firstElementChild);
    }
    return audioEl;
}
</script>
<template>
    <div class="sound f-dfc" :key="voiceUrl" :class="[playing ? 'playing' : '', 'f-csp']" :title="playing ? '停止播放' : '播放'" @click.stop="playVoice" v-if="voice && voice.status==1">
        {{ voiceDuration }}
    </div>
</template>
<style lang="less" scoped>
.sound {
    width: 102px;
    height: 22px;
    // line-height: 22px;
    padding-left: 10px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    &::before {
        content: "";
        width: 11px;
        height: 13px;
        display: inline-block;
        margin-right: 5px;
        background: url(@/assets/icon/play.png) no-repeat center/contain;
    }
}
.playing {
    &::before {
        background: url(@/assets/icon/play.gif) no-repeat center/contain;
    }
}
</style>
