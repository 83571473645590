<script setup>
import Login from "@/views/login.vue";
import Register from "@/views/register.vue";
// import { getFingerPrint } from "@/utils";

const router = useRouter();
const store = appStore();
const loginType = ref(0);
const { token,userId, isShowChatBox, followListType, unReadCountTotal,currentRoomID } = storeToRefs(store);

const loginComp = ref(null);
const qrcodeUrl = `https://gzh.cdddian.com/html/Download.html?invitationCode=${sessionStorage.inviteCode || 1872}`;
//初始化TIM
store.initTIM();
timLogin();
async function logout(){
    if(currentRoomID.value){
        await fly.exitLiveRoom({ roomid: currentRoomID.value });
    }
    await fly.getLogout();
    store.logout();
    router.replace("/");
}
// onMounted(() => {
//     postAccessRecords();
// });
watch(token, newToken => {
    if (newToken) {
        timLogin(); //登录
        // postAccessRecords();
    } else {
        store.$patch(state => {
            state.isShowChatBox = false;
            state.currentChatUser = null;
        });

        store.timHandle && store.timHandle.logout(); //退出
    }
});

//聚焦窗口时 验证localStorage.userInfo是否被其它页面修改--比如个人中心的修改
window.onfocus = () => {
    if (store.userInfo && JSON.stringify(store.userInfo) != localStorage.userInfo) {
        store.updateUserInfo();
    }
};

if (store.token) {
    store.updateUserInfo();
}

//登录TIM
function timLogin() {
    if (store.timHandle && token.value) {
        store.timHandle
            .login()
            .then(data => {})
            .catch(error => {});
        /*if(navigator.storage){
            navigator.storage.estimate().then(cache =>{
                const {quota,usage} = cache
                const percent = ((usage / quota) * 100).toFixed(2)
                if(percent<90){//当前缓存占用小于90%开启日志收集
                    store.collectLog();
                }
                // console.log('缓存使用：==>',percent,store.isCollectionLog);
            })
        }*/
    }
}

//收集访问记录
/*async function postAccessRecords() {
    if (!token.value) return;

    try {
        const deviceCode = await getFingerPrint();
        const system = navigator.userAgent.indexOf("Mac OS") !== -1 ? "Mac OS" : "Windows";
        const remark = JSON.stringify({ system, localTime: new Date().toLocaleString(), deviceCode });
        fly.postAccessRecords({ remark });
    } catch (error) {
        console.error(error);
    }
}*/

//打开客服
function showChatBoxCustomerService() {
    if (!token.value) return; //未登录账号
    if (!store.timHandle || !store.timHandle.isSdkReady) {
        Toast.fail("聊天未连接");
        return; //未登录TIM
    }
    store.openServiceDialog()
    /*router.push(`/kf`);
    store.$patch(state => {
        state.isShowChatBox = false;
        nextTick(() => {
            state.isShowChatBox = true;
            state.currentChatUser = {
                conversationID: "C2C" + "10", //10为客服uid 、13为未成年退款客服uid
            };
        });
    });*/
}
//打开聊天框
function showChatBox(show, currentChatUser = null) {
    if (!token.value) return; //未登录账号
    if (!store.timHandle || !store.timHandle.isSdkReady) {
        Toast.fail("聊天未连接");
        return; //未登录TIM
    }
    store.$patch(state => {
        state.isShowChatBox = typeof show === "boolean" ? show : !isShowChatBox.value;
        state.currentChatUser = currentChatUser;
    });
}

function openLoginDialog(type) {
    loginType.value = type;
    store.openLoginDialog = true;
}
function goToLogin(type) {
    loginType.value = 0;
    nextTick(() => {
        if (loginComp.value) {
            loginComp.value.signType = type;
        }
    });
}

//打开关注列表
function openFollowList(type) {
    if (typeof type !== "number") return;
    store.$patch(state => {
        state.followListType = 0;
    });
    nextTick(() => {
        store.$patch(state => {
            state.followListType = type;
        });
    });
}
//接收iframe充值操作
window.addEventListener('message', function(e){
    if(e.data.handler){
        store.firstChargeUrl="";
        store.openRechargeDialog()
    }
}, false);
</script>
<template>
    <div class="f-dfc head f-pr">
        <img src="@/assets/logo.png" alt="小c语音" class="head-logo" />
        <nav class="f-fx nav f-tac">
            <router-link to="/">首页</router-link>
            <router-link to="/hall">大厅</router-link>
            <a class="f-pr download-wrap" href="javascript:;">
                下载
                <div class="download-item f-pa f-tac">
                    <!-- <img src="@/assets/qrcode.png" alt="" /> -->
                    <div class="qrcode-wrap"><qrcode-vue :value="qrcodeUrl" :margin="2" :size="120" level="H" /></div>
                    <p>扫码下载小C</p>
                    <!-- <div class="f-fx1"> f-fx 
                    </div>
                    <div class="f-fx1">
                        <img src="@/assets/qrcode.png" alt="">
                        <p>Android版</p>
                    </div> -->
                </div>
            </a>
        </nav>
        <section class="f-fx1 f-dfc head-right">
            <template v-if="store.userInfo && token">
                <Search />
                <p class="head-diamond f-dfc">{{ store.userInfo.diamond }}</p>
                <p class="head-recharge btn f-fs12 f-tac f-csp" @click="store.openRechargeDialog">
                    充值
                </p>

                <p class="f-dfc head-info" :class="{ new: unReadCountTotal }" @click="showChatBox">消息</p>
                <!-- 聊天对话框 -->
                <Teleport to="body">
                    <transition name="fade-scale">
                        <ChatBox v-if="isShowChatBox" />
                    </transition>
                </Teleport>
                <!-- 关注列表 -->
                <Teleport to="body">
                    <transition name="fade">
                        <FollowList v-if="followListType" />
                    </transition>
                </Teleport>
                <SystemMessage></SystemMessage>
                <Recharge></Recharge>
                <div class="head-nickname f-dfc f-pr f-csp">
                    <router-link to="/user-profile/detail" :target="$route.name === 'chat-room' ? '_blank' : '_self'">
                        <img :src="`${store.userInfo.headPortrait}?x-oss-process=image/resize,m_lfit,h_35,w_35`" class="head-face f-csp" />
                    </router-link>
                    <NickName :background="store.userInfo.nicknameColor" :title="store.userInfo.nickname" class="f-toc1 nickname-text">
                        {{ store.userInfo.nickname }}
                    </NickName>
                    <section class="f-pa f-tac drop-user">
                        <ul class="f-fx drop-user-top">
                            <li class="f-fx1 f-un" @click="openFollowList(1)">
                                <p class="f-wb">{{ store.userInfo.followCount }}</p>
                                <p class="f-fs12 f-c999">关注</p>
                            </li>
                            <li class="f-fx1 f-un" @click="openFollowList(2)">
                                <p class="f-wb">{{ store.userInfo.fansCount }}</p>
                                <p class="f-fs12 f-c999">粉丝</p>
                            </li>
                            <li class="f-fx1 f-un" @click="openFollowList(3)">
                                <p class="f-wb">{{ store.userInfo.intervieweeCount }}</p>
                                <p class="f-fs12 f-c999">访客</p>
                            </li>
                            <li class="f-fx1 f-un" @click="openFollowList(4)">
                                <p class="f-wb">{{ store.userInfo.visitorCount }}</p>
                                <p class="f-fs12 f-c999">足迹</p>
                            </li>
                        </ul>
                        <ul class="f-fx f-fs14 f-tac drop-user-bottom">
                            <li class="f-fx1"><router-link to="/user-profile/detail" :target="$route.name === 'chat-room' ? '_blank' : '_self'">个人主页</router-link></li>
                            <!-- <li class="f-fx1"><router-link to="/grade" :target="$route.name === 'chat-room' ? '_blank' : '_self'">我的等级</router-link></li> -->
                            <li class="f-fx1" @click="logout">退出登录</li>
                        </ul>
                    </section>
                </div>
                <div class="f-pa head-right-menu">
                    <!-- <transition name="fade">
                        <div
                            class="music"
                            @click="store.shareScreen"
                            v-if="store.isOnSeat && store.trtcHandle && store.trtcHandle.localStream"
                            :title="store.trtcHandle.screenStream ? '取消共享系统音频' : '共享系统音频'"
                        >
                            <img src="@/assets/icon-menu7.png" alt="" />
                        </div>
                    </transition> -->
                    <div class="customer-service" @click.stop="showChatBoxCustomerService">
                        <img src="@/assets/kf.png" class="f-csp" title="点击打开客服" />
                    </div>
                </div>
            </template>
            <template v-else>
                <p @click="openLoginDialog(1)" class="f-csp">注册</p>
                <p @click="openLoginDialog(0)" class="f-csp">登录</p>
            </template>
        </section>
    </div>
    <van-dialog width="860" class="login-box" v-model:show="store.openLoginDialog" :show-confirm-button="false" :closeOnClickOverlay="true" teleport="body">
        <section class="f-fx">
            <img class="illustration" src="https://oss.cdddian.com/im/2023-11-14/2e6037b12b1e4b51a1c17d20bc6fc4cb222177.jpg" width="380" height="560" />
            <div id="login-right" v-if="store.openLoginDialog">
                <Register @goToLogin="goToLogin" v-if="loginType" />
                <Login @goToRegister="loginType = 1" ref="loginComp" v-else />
            </div>

            <van-icon name="cross" size="16" color="#999" class="f-pa login-close f-csp" @click="store.openLoginDialog = false" />
        </section>
    </van-dialog>
    <ImKf />
    <!-- <DialogFirstCharge /> -->
    <!-- <DialogFirstChargeDraw /> -->
</template>
<style lang="less" scoped>
.head {
    z-index: 10;
    min-height: 72px;
    padding: 14px 20px 14px 0;
    /* height: 100vh; */
    /* flex:.1; */
    /* flex-direction: column;
    align-items: center;
    background-color: beige;*/
}
.head-recharge {
    width: 46px;
    height: 20px;
    border-radius: 5px;
}
.head-diamond {
    &::before {
        content: "";
        width: 18px;
        height: 14px;
        margin-right: 5px;
        display: inline-block;
        background: url(@/assets/diamond.png) no-repeat center center/100%;
    }
}
.head-info {
    cursor: pointer;
    user-select: none;
    &::before {
        content: "";
        width: 19px;
        height: 18px;
        margin-right: 5px;
        display: inline-block;
        background: url(@/assets/icon-msg.png) no-repeat center center/100%;
    }
    &.new {
        position: relative;
        &::after {
            content: "";

            position: absolute;
            top: 2px;
            left: 17px;

            display: block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #ff073d;
        }
    }
}
.head-nickname {
    // padding:0 15px 0;
    // background-color: red;
    a {
        display: inline-flex;
        justify-content: center;
    }
    .nickname-text {
        margin-left: 10px;
    }
    > p {
        max-width: 100px;
    }
    &:hover section {
        // visibility: visible;
        display: block;
        // opacity: 1;
    }
    &::after {
        content: "";
        width: 10px;
        height: 6px;
        margin-left: 5px;
        display: inline-block;
        background: url(@/assets/icon-down.png) no-repeat center center/100%;
    }
}
.drop-user {
    z-index: 100;
    top: 35px;
    right: 0;
    // opacity: 0;
    // visibility: hidden;
    display: none;
    // transition: opacity 0.2 ease-in;
    // width: 304px;
    border-radius: 5px;
    background-color: #150d3b;
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.drop-user-top {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.drop-user-bottom li:hover,
.drop-user-bottom li:hover a {
    color: #a97eff;
}
.head-logo {
    margin: 0 55px 0 30px;
}
.nav a:hover {
    color: @color-white;
    transform: scale(1.2);
    /*background-color: rgb(209, 209, 209);
    font-size: 35px;
     display: block; */
}
.nav a {
    /* height: 35px;
    width:100%;
    line-height: 35px; */
    line-height: 1;
    transition: transform 0.3s;
    color: rgba(255, 255, 255, 0.3);
    padding: 10px 20px;
}
.nav a.router-link-exact-active {
    /* background-color: azure; */
    color: @color-white;
}
.head-right {
    justify-content: flex-end;
}
.head-right p {
    margin: 0 20px;
    white-space: nowrap;
}
.head-face {
    width: 35px;
    height: 35px;
    border-radius: 30px;
}
.download-wrap:hover > div {
    // opacity: 1;
    display: block;
}
.download-item {
    top: 40px;
    left: 0;
    // opacity: 0;
    font-size: 14px;
    display: none;
    width: 220px;
    height: 217px;
    padding-top: 40px;
    background: linear-gradient(141deg, #150d3b 0%, #05071c 100%);
    box-shadow: 0px 2px 12px 0px rgba(5, 7, 28, 0.7);
    border-radius: 8px;
    border: 1px solid;
    border-image: linear-gradient(135deg, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.1)) 1 1;
}
.qrcode-wrap {
    width: 120px;
    height: 120px;
    margin: 0 auto 10px;
}
.login-close {
    right: 20px;
    top: 15px;
    user-select: none;
}
#login-right {
    position: relative;
    display: flex;
    width: 100%;
}
.head-right-menu {
    right: -60px;
    bottom: calc(100px - 100vh);

    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0;
    pointer-events: none;
    .music {
        background: linear-gradient(90deg, #48416a, #3b355c);
        border-radius: 50%;
        .rect(40px);
        .middle();
        img {
            .rect(30px);
        }
    }
    .fade(0.2s);
    > * {
        cursor: pointer;
    }
    .customer-service {
        // border: 1px solid red;
        z-index: -1;
        //防止挡住个人中心滚动条
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .rect(65%);
            border-radius: 50%;
            // border: 1px solid red;
            pointer-events: all;
        }
    }
}
</style>
<style lang="less">
.login-box {
    --van-dialog-border-radius: 8px;
    background: linear-gradient(141deg, #150d3b 0%, #05071c 100%);
    box-shadow: 0px 2px 12px 0px rgba(5, 7, 28, 0.7);
    border: 1px solid rgba(255, 255, 255, 0.02);
    .illustration {
        object-fit: cover;

        background: #1b1f41;
    }
}
</style>
