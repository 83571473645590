<script setup>
import hexMD5 from "@/utils/md5.js";
const emit = defineEmits(["onJoinRoom"]);
const inputPassword = ref(""); //密码输入
const store = appStore();
const router = useRouter();

watch(
    () => store.openVerifyPwdDialog,
    val => {
        if (val) {
            inputPassword.value = "";
            store.dialogInputPassword = "";
        }
    }
);

//提交密码
async function onSubmit() {
    if (!store.enterRoomId) {
        return Toast("房间ID错误！");
    }
    if (!inputPassword.value) {
        return Toast("请输入密码！");
    }
    const {
        data: { isAccess, password },
        code,
    } = await fly.checkRoomAccessNew({ roomId: store.enterRoomId });
    if (code === 10034) {
        router.go(-1);
        return Toast.fail("加入房间失败");
    }
    if (password !== hexMD5(String(inputPassword.value))) {
        return Toast.fail("密码错误！");
    }
    store.dialogInputPassword = inputPassword.value;
    // sessionStorage.pwd = inputPassword.value;

    if (store.isClickOverlayVerifyPwdDialog) {
        store.openVerifyPwdDialog = false;
        router.push(`/chat-room/${store.enterRoomId}`);
    } else {
        emit("onJoinRoom", inputPassword.value);
    }
}
function goBack() {
    // store.isClickOverlayVerifyPwdDialog = true;
    store.openVerifyPwdDialog = false;
    router.go(-1);
}
</script>
<template>
    <van-dialog
        class="f-cfff input-pwd-dialog f-tac"
        v-model:show="store.openVerifyPwdDialog"
        title="提示"
        :show-confirm-button="false"
        :closeOnClickOverlay="store.isClickOverlayVerifyPwdDialog"
        teleport="body"
    >
        <p><input class="input-pwd f-w80" placeholder="请输入房间密码" v-model.trim="inputPassword" maxlength="10" type="text" /></p>
        <div class="f-fx input-pwd-wrap">
            <button class="input-pwd-btn cancel-pwd-btn f-fx1 f-csp" v-if="!store.isClickOverlayVerifyPwdDialog" @click="goBack">返回</button>
            <button class="input-pwd-btn confirm-pwd-btn f-fx1 f-csp" @click="onSubmit">提交</button>
        </div>
    </van-dialog>
</template>
<style lang="less" scoped>
.input-pwd {
    border: none;
    font-size: 14px;
    background: rgba(255, 255, 255, 0.07);
}
.input-pwd-btn {
    font-size: 14px;
    // max-width: 100px;
    // margin: auto;
}
</style>
