import App from "./App.vue";
import router from "./router";
import DisableDevtool from 'disable-devtool';
// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";
// import "vue-virtual-scroller/dist/vue-virtual-scroller.css"; // 引入它的 css
// import VueVirtualScroller from "vue-virtual-scroller"; // 引入它
// import lazyPlugin from "vue3-lazy";
// import Viewer from "v-viewer";
// import { useRegisterSW } from "virtual:pwa-register/vue";
// import { createPinia } from "pinia";
// import mitt from 'mitt';
import vClickOutside from "click-outside-vue3";
import { directive } from "vue3-menus";
import QrcodeVue from 'qrcode.vue'
// import "viewerjs/dist/viewer.css";
// import "animate.css/animate.css";
import "swiper/swiper.less";
import "swiper/components/effect-fade/effect-fade.less";
import "@/assets/css/style.css";
import "@/assets/css/config.less";

// import BrowserLogger from 'alife-logger'
import { Loading, Icon, Dialog, Popup, Lazyload,Swipe, SwipeItem,Switch,Radio,Search,RadioGroup } from "vant";//,CountDown
// import loadingImg from "./assets/100x100.png";
import registerDirective from "@/utils/directive";
//禁止打开调试
if (import.meta.env.PROD) {
    DisableDevtool({
        md5:'831c4baa8a44083a6434b892d573846b',
        tkName:'allow',
        timeOutUrl:'/404.html'
    });
}
// useRegisterSW();
// 错误日志
// import useErrorHandler from './error-log'
if (innerWidth < 640) {
    //手机打开跳转到app下载
    location.href = "https://gzh.cdddian.com/html/Download.html?invitationCode=1872";
}
window.kfId = import.meta.env.VITE_IM_KFID
const app = createApp(App);
// const bus = mitt();
// app.provide('$bus', bus);
const pinia = createPinia();
pinia.use(({ store }) => {
    store.$router = markRaw(router);
});
app.use(pinia);
// app.use(VueVirtualScroller)
app.use(Loading);
app.use(Icon);
app.use(Popup);
app.use(Swipe);
app.use(SwipeItem);
app.use(Radio);
app.use(RadioGroup);
app.use(Switch);
app.use(Search);
app.use(Dialog);
app.use(vClickOutside);
app.use(Lazyload);
// app.use(lazyPlugin, {
//     loading: loadingImg,
//     error: loadingImg
// });
Toast.allowMultiple();
/*
Sentry.init({
    app,
    dsn: "https://869d123ee9c4493b83e20802d0a195cd@o1371642.ingest.sentry.io/6675986",
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost", "my-site-url.com", /^\//],
        }),
    ],
    environment: import.meta.env.MODE,
    maxBreadcrumbs: 10,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});
let user = null;
if (localStorage.userInfo) {
    const userInfo = JSON.parse(localStorage.userInfo);
    user = { id: userInfo.uid, username: userInfo.nickname, mobile: userInfo.mobile, level: userInfo.level, charm: userInfo.charm };
}
Sentry.setUser(user);
*/
app.use(router);
// window._bus = bus;
// useErrorHandler(app)
// app.component('vue3-menus', Vue3Menus);
registerDirective(app);
app.component("QrcodeVue",QrcodeVue);
app.directive("menus", directive);
app.mount("#app");


/*// console.log(BrowserLogger);
//生产环境下加载百度统计
if (import.meta.env.PROD) {
    window._hmt = window._hmt || [];
    window._hmt.push(['_setAccount', "d1ec51dcd252af59c91741b6f1fb292f"]);

    (function () {
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?d1ec51dcd252af59c91741b6f1fb292f";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
    })();
}*/
