import { load } from "./fingerprint";
// import {useRoute} from 'vue-router'
export function getUrlParams(name) {
    /*const route = useRoute()
    console.log(route)*/
    var se = location.search;
    return new URLSearchParams(se).get(name) || "";
}
/**
     * 传入对象返回url参数
     * @param {Object} data {a:1}
     * @returns {string}
     */
function getParam(data){
    let url = '';
    for(var k in data){
        let value = data[k] !==undefined ? data[k] : '';
        url += `&${k}=${encodeURIComponent(value)}`
    }
    return url ? url.substring(1) : ''
}

/**
 * 将url和参数拼接成完整地址
 * @param {string} url url地址
 * @param {Json} data json对象
 * @returns {string}
 */
export function getUrl(url, data){
    //看原始url地址中开头是否带?，然后拼接处理好的参数
    return url += (url.indexOf('?') < 0 ? '?' : '') + getParam(data)
}
/**
 * @function 参数拼接
 * @param {object} obj 只支持非嵌套的对象
 * @returns {string}
 */
export function margeParams(obj) {
    let result = '';
    let item;
    for (item in obj) {
      if (obj[item] && String(obj[item])) {
        result += `&${item}=${obj[item]}`;
      }
    }
    if (result) {
      result = '&' + result.slice(1);
    }
    return result;
}
export function link(url, blank = false) {
    //const {VITE_APP_suffix} = import.meta.env
    //链接跳转
    if (!url) {
        location.reload();
        return;
    }
    if (blank) {
        open(url); //+VITE_APP_suffix
    } else {
        location.href = url; //+VITE_APP_suffix
    }
}
export function getImageUrl(src) {
    return new URL(src, import.meta.url).href;//import(src);
}
/*
时间对比函数
参数说明
hisTime:表示历史时间戳
nowTime:表示当前时间戳
调用示例
diffDateTime(1400047164000,1410047164000)
*/
export function diffDateTime(hisTime, nowTime) {
    var now = nowTime ? nowTime : new Date().getTime(),
        hisTimeString = (typeof hisTime==='string' && hisTime.includes('-')) ? hisTime : +hisTime,
        deadline = typeof hisTime === 'number' ? hisTime : +new Date(hisTimeString),
        diffValue = now - deadline,
        result = "",
        minute = 1000 * 60,
        hour = minute * 60,
        day = hour * 24,
        month = day * 30,
        year = month * 12,
        _year = diffValue / year,
        _month = diffValue / month,
        _week = diffValue / (7 * day),
        _day = diffValue / day,
        _hour = diffValue / hour,
        _min = diffValue / minute;
        
    if (_year > 10) result = "";
    else if (_year >= 1) result = parseInt(_year) + "年前";
    else if (_month >= 1) result = parseInt(_month) + "个月前";
    else if (_week >= 1) result = parseInt(_week) + "周前";
    else if (_day >= 1) result = parseInt(_day) + "天前";
    else if (_hour >= 1) result = parseInt(_hour) + "小时前";
    else if (_min >= 1) result = parseInt(_min) + "分钟前";
    else result = "刚刚";
    return result;
}
/*
时间对比函数,返回剩余天数
参数说明
hisTime:表示历史时间戳
nowTime:表示当前时间戳
调用示例
diffDateTime(1400047164000,1410047164000)
*/
export function diffByDayTime(hisTime) {//, nowTime
    var now = +new Date(),
        deadline = +new Date(hisTime),
        diffValue = deadline - now,
        result = "",
        minute = 1000 * 60,
        hour = minute * 60,
        day = hour * 24,
        _day = diffValue / day,
        _hour = diffValue / hour,
        _min = diffValue / minute;
    if (_day >= 1) result = `剩${Math.ceil(_day)}天`;
    else if (_hour >= 1) result = `剩${Math.ceil(_hour)}小时`;
    else if (_min >= 1) result = `剩${Math.ceil(_min)}分钟`;
    else if (_min > 0) result = `剩${Math.ceil(_min)}分钟`;
    else result = '剩0分钟';
    return result;
}
/**
 * 计算两个日期之间的天数
 * @param dateString1  开始日期 yyyy-MM-dd
 * @param dateString2  结束日期 yyyy-MM-dd
 * @returns {number} 如果日期相同 返回一天 开始日期大于结束日期，返回0
 */
export function getDaysBetween(dateString1, dateString2) {
    var startDate = Date.parse(dateString1);
    var endDate = Date.parse(dateString2);
    if (startDate > endDate) {
        return 0;
    }
    if (startDate == endDate) {
        return 1;
    }
    var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
    return days;
}
export function countDown(e, t, n, a) {
    //
    var i = 1e3 * (t - e),
        o = Math.floor(i / 864e5),
        r = Math.floor(i / 36e5) % 24,
        s = Math.floor(i / 6e4) % 60,
        l = Math.floor(i / 1e3) % 60;
    if (((o = o < 10 ? "0" + o : o), (r = r < 10 ? "0" + r : r), (s = s < 10 ? "0" + s : s), (l = l < 10 ? "0" + l : l), n(o, r, s, l), 0 == o && 0 == r && 0 == s && 0 == l)) return a(), !1;
    setTimeout(function () {
        countDown(t, n, a);
    }, 1e3);
}
export function isEmpty(str) {
    //检验是否为空
    const val = str.replace(/\s+/g, "");
    return val.length === 0 ? true : false;
}
export function isPhone(str) {
    //校验手机号码
    const exp = /^1\d{10}$/;
    return exp.test(str) ? true : false;
}
export function regEmail(email) {
    // 替换邮箱字符
    if (String(email).indexOf("@") > 0) {
        var str = email.split("@"),
            _s = "";
        if (str[0].length > 3) {
            for (var i = 0; i < str[0].length - 3; i++) {
                _s += "*";
            }
        }
        var new_email = str[0].substr(0, 3) + _s + "@" + str[1];
    }
    return new_email;
}
export function regMobile(mobile) {
    // 替换手机字符
    var new_mobile = mobile;
    if (mobile.length > 7 && !isNaN(mobile)) {
        new_mobile = mobile.substr(0, 3) + "****" + mobile.substr(7);
    }
    return new_mobile;
}
export async function getFingerPrint() {
    let deviceCode;
    try {
        const FingerprintJS = await load();
        const { visitorId } = await FingerprintJS.get();
        deviceCode = visitorId;
    } catch (e) {
        console.log("e :>> ", e);
    }

    //验证设备码格式
    if (/^[A-Za-z0-9]+$/.test(deviceCode)) {
        deviceCode = deviceCode;
    } else if (/^[A-Za-z0-9|\_]{32}$/.test(localStorage.d_code)) {
        //获取本地保存的随机设备码
        deviceCode = 'random_' + localStorage.d_code;
    } else {
        //随机设备码
        const d_code = randomString(32);
        localStorage.d_code = d_code;
        deviceCode = "random_" + d_code;
    }
    deviceCode = 'web_' + deviceCode;
    return deviceCode;
}

//随机字符串设备码
export function randomString(e) {
    e = e || 32;
    const t = "abacdefghjklmnopqrstuvwxyz0123456789";
    const a = t.length;
    let n = "";
    for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
    return n;
}

/**
 *
 * @param {*} dateNow Date实例 传日期时间(不传，默认取当前时间)
 * @param {*} intervalDays Number类型 间隔时间
 * @param {*} bolPastTime Boolean类型 true表示之前，false表示之后
 * @returns Array<Object> 返回Array，内部子项目为Object
 * 调用示例：getDateRange('',3,true)表示取当前时间的前3天的日期
 * 返回示例：[{text: '2022-07-14', timestamp: 1657756800000}
 * ,{text: '2022-07-15', timestamp: 1657843200000}
 * ,{text: '2022-07-16', timestamp: 1657929600000}]
 */
export function getDateRange(dateNow, intervalDays, bolPastTime = true) {
    const nowTime = dateNow || new Date(),
        currentItem = { text: formateDate(nowTime), timestamp: nowTime.getTime() },
        oneDayTime = 24 * 60 * 60 * 1000;
    let list = bolPastTime ? [] : [currentItem];
    let lastDay;
    let day = bolPastTime ? intervalDays : 1;
    while (day) {
        if (bolPastTime) {
            --day;
            if (day <= 0) {
                break;
            }
        } else {
            ++day;
            if (day > intervalDays) {
                break;
            }
        }
        if (bolPastTime) {
            lastDay = new Date(nowTime.getTime() - day * oneDayTime);
        } else {
            lastDay = new Date(nowTime.getTime() + (day - 1) * oneDayTime);
        }
        list.push({ text: formateDate(lastDay), timestamp: lastDay.getTime() });
    }
    if (bolPastTime) {
        list.push(currentItem);
        list.reverse();
    }
    return list;
}
function formateDate(time) {
    let year = time.getFullYear();
    let month = time.getMonth() + 1;
    let day = time.getDate();
    if (month < 10) {
        month = "0" + month;
    }
    if (day < 10) {
        day = "0" + day;
    }
    return `${year}-${month}-${day}`;
}

/**
 * @desc 节流函数       //第一次马上触发，最后一次事件稳定触发，利于得到最后一次的状态
 * @param {Function} func - 需要防抖的函数
 * @param {Number} ms - 延迟时间
 *
 */
export function throttle(func, ms) {
    let isThrottled = false,
        savedArgs,
        savedThis;

    function wrapper() {
        if (isThrottled) {
            savedArgs = arguments;
            savedThis = this;
            return;
        }

        func.apply(this, arguments);

        isThrottled = true;

        setTimeout(function () {
            isThrottled = false;
            if (savedArgs) {
                wrapper.apply(savedThis, savedArgs);
                savedArgs = savedThis = null;
            }
        }, ms);
    }

    return wrapper;
}

/**
 * @desc 防抖函数
 * @param {Function} func 需要防抖的函数
 * @param {Number} ms 延迟时间
 *
 */
export function debounce(func, ms) {
    let timeout;
    return function () {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, arguments), ms);
    };
}
/**
 * @desc 优化白屏函数
 * @param {Number} maxCount 
 * @returns 
 */
export function useDefer(maxCount = 100){
    const frameCount = ref(0) //定义一个计数器变量，用于控制 defer 动画的次数，当为 0
    const instance = getCurrentInstance() //获取当前实例对象，可以是 vm 对象或自定义实例。这个对象包含了一些方法，用于控制动画。 （请参见该实例的方法） 创建 defer 动画时，将 frameCount.value 加 1 并将其写入到动画定时器中，每当该变量的值大于等于 1 时，将重新开始定时器，以便在定时器中计数。
    let rafId;
    function updateFrameCount() {
        rafId = requestAnimationFrame(() => {      //requestAnimationFrame来设置当前已经渲染到哪一帧了
            frameCount.value++
            if(frameCount.value >= maxCount){
                cancelAnimationFrame(rafId)
                return;
            }
            updateFrameCount()
        })
    }
    updateFrameCount();

    onUnmounted(() => {
        cancelAnimationFrame(rafId)
    }, instance)

    return function defer(n){
        return frameCount.value >= n
    }
}
const formatRoundNum = (num, pre) => (Math.floor(num * Math.pow(10, pre)) / Math.pow(10, pre)).toFixed(pre);
/**
 * 格式化礼物值
 * @param {Number} score 
 * @returns {String}
 */
export function formatScore(score) {
    return score > 9999 ? formatRoundNum(score / 10000,1) + "w" : score;
}
/**
 * 获取特效类型
 * @param {String} url 
 * @param {String} type 
 * @returns {String}
 */
export function getEffectType(url,type = '') {
    // if (!url) return "";
    // else if (/\.svga$/.test(url)) return "svga";
    // else if (/\.mp4$/.test(url)) return "mp4";
    // else return "img";
    return url ? url.endsWith(type) : ''
}
/**
 * 批量请求
 * @param {Array} urls 请求地址
 * @param {Number} concurrencyLimit 最大请求并发数量
 */
export async function batchRequest(urls, concurrencyLimit) {
    const results = [];
    const executing = [];
    for (const url of urls) {
      const promise = fetch(url); // 假设使用 fetch 函数发送网络请求
      results.push(promise);
      const combined = Promise.resolve(promise).then(() => {
        executing.splice(executing.indexOf(combined), 1);
      });
      executing.push(combined);
      if (executing.length >= concurrencyLimit) {
        await Promise.race(executing);
      }
    }
    return Promise.all(results);
  }
  /**
   * 计算当前帧率
   * @param {Number} every 计算间隔多久 默认10 
   * @returns Ref<number>
   */
export function useFps(every = 10){
    const fps = ref(0);
    // 用来记录起始时间
    let last = performance.now();
    let ticks = 0;
    const update = () => {ticks += 1;
    // 间隔到了再重新计算
    if (ticks >= every) {
        const now = performance.now();
        // 计算出这一帧用了多久
        const diff = now - last;//用 10ms 去除以 每一用的平均ms 可以得出顿数
        fps.value = Math.round(1000 / (diff / ticks));
        last = now;
        ticks = 0;
    }
    requestAnimationFrame(update);
    }
    requestAnimationFrame(update)
    return fps;
}
/**
 * 数值更新函数
 * @param {Object} param0 
 * from 起始数字
 * to 最终数字
 * duration 延迟间隔(ms)
 * onProgress 更新回调函数
 */
export function useNumberAnimation({from, to, duration, onProgress }){
    let value = from;
    // 算出增长速度
    const speed = (to-from) / duration;
    // 起始时间
    const startTime = Date.now();
    const run= ()=> {
        //时间间隔
        const t = Date.now() - startTime;//当时间间隔大于延迟时间时终止
        if(t >= duration){
            value = to;
            if(onProgress) onProgress(value);
            return;
        }
        // 当前值 = 起始值 + 时间间隔 *增长速度
        value = from + t*speed;
        if(onProgress) onProgress(value);
        //继续执行
        requestAnimationFrame(run);
    };
    run();
}
/**
 * 字符串替换函数
 * @param {string} str 需要替换的字符串
 * @param {number} startLen 开始替换的长度
 * @param {number} endLen 结束替换的长度
 * @param {string} maskType 替换的点位符号 默认是*
 * @param {number} num 点位符号数量
 * @returns string 处理后的字符串
 */
export function maskString(str, startLen = 3, endLen = 4, maskType = '*', num = 4) {
    // 确保字符串足够长以进行处理
    if (str.length <= startLen + endLen) {
        return str;
    }
    const start = str.slice(0, startLen);
    const end = str.slice(-endLen);
    let maskStr = '';
    for (let i=0;i<num;i++) {
        maskStr += maskType;
    }
    return `${start}${maskStr}${end}`;
}