<script setup>
const props = defineProps({
    vipLevel: Number,
    isVipExpired: [Boolean, Number],
});

//vip文字描述
const vip = computed(() => (props.vipLevel ? "VIP" + props.vipLevel : ""));

//是否大于5级
const isSuperVip = computed(() => props.vipLevel > 5);
</script>

<template>
    <div class="vip" :class="{ svip: isSuperVip, expired: isVipExpired }" v-if="vip">
        <span>{{ vip }}</span>
    </div>
</template>

<style lang="less" scoped>
.vip {
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 12px;
    color: #685423;

    width: 64px;
    height: 26px;
    margin-top: 2px;
    padding-left: 19px;

    background: no-repeat center/contain;
    background-image: url("@/assets/profile/VIP.png");

    // user-select: none;

    // span {
    //     transform: scale(0.8);
    // }
    &.expired {
        background-image: url("@/assets/profile/VIP_expire.png");
        color: #666666;
    }
    &.svip {
        background-image: url("@/assets/profile/SVIP.png");
        color: #c7ae7a;
        &.expired {
            background-image: url("@/assets/profile/SVIP_expire.png");
            color: #666666;
        }
    }
}
</style>
