<script setup>
import VerifyPwd from "./verify-pwd.vue";
const router = useRouter();
//const showTips = ref(""); 显示弹窗提示
const store = appStore();
const state = reactive({
    listData: [],
    page: 0,
    type: 2,
    total: 80,
    noMore: false,
});

if (store.userId && store.userInfo && store.userInfo.gender === 2) {
    state.type = 1;
}

const mainFind = ref(null);

//监听列表数据长度，不足一页或不足换行时继续拉取
/*watch(
    () => state.listData.length,
    (newValue, oldValue) => {
        if (newValue < 15 || Math.ceil(newValue / 5) === Math.ceil(oldValue / 5)) loadMore();
    }
);*/
async function tab(type) {
    //切换tab回到顶部
    if (mainFind.value) {
        mainFind.value.scrollTop = 0;
    }
    state.type = type;
    state.page = 1;
    try {
        const {
            page: { list, currPage, totalPage, pageSize },
        } = await fly.getHome({
            pageIndex: 1,
            pageSize: state.total,
            type: state.type,
        });
        if (list && list[0]) {
            state.listData = list;
        }
    } catch (error) {
        console.log("错误信息：", error);
    }
}
async function loadMore() {
    if (state.noMore) {
        return false;
    }
    ++state.page;
    try {
        const {
            page: { list, currPage, totalPage, pageSize },
        } = await fly.getHome({
            pageIndex: state.page,
            pageSize: state.total,
            type: state.type,
        });
        // console.log(list);
        if (currPage >= totalPage) {
            state.noMore = true;
        }
        if (list && list[0]) {
            state.listData.push(...list);
        }
    } catch (error) {
        console.log("错误信息：", error);
    }
}
async function getChatType() {
    try {
        const { data } = await fly.getChatTypeList();
        const chatType = ["游戏", "相亲", "电台"];
        const filterData = data.filter(el => {
            if (!chatType.includes(el.type)) {
                return el;
            }
        });
        store.setChatType(filterData);
    } catch (error) {
        console.log("错误信息：", error);
    }
}
if (!store.chatType) {
    getChatType();
}

function navigate(accompanyId) {//async 跳转个人资料
    if (!store.token) {
        store.openLoginDialog = true;
        return false;
    }
    if (!accompanyId) {
        console.error("No Accompany Id");
        return;
    }
    store.previewUserInfo(accompanyId);
    /*Toast.loading({
        message: "加载中...",
        forbidClick: true,
    });
    //检查房间状态
    const { data: roomStatus } = await fly.checkAccompanyStatus({ accompanyId });
    Toast.clear();
    if (!roomStatus.roomId) {
        console.error("Room No Ready");
        // showTips.value = "Room No Ready";
        // Toast.fail("房间不可用！")
        
        return;
    }
    // showTips.value = "加载中..."
    router.push(`/chat-room/${roomStatus.roomId}`);*/
}
//处cp广告位
// async function getCpAdvertising() {
//     try {
//         const { data } = await fly.getBanner({advertisingType:5,locationType:2});
//         console.log(data);
//     } catch (error) {
//         console.log("错误信息：", error);
//     }
// }
// getCpAdvertising()
function toChat(userInfo) {
    state.isOpen = false;
    store.openChatDialog({
        nick: userInfo.nickname,
        uid: userInfo.uid,
        avatar: userInfo.headPortrait,
        conversationID: "C2C" + userInfo.uid,
    });
}
// previewUserInfo(1011830)
</script>
<template>
    <nav class="f-fx main-menu">
        <p :class="{ 'menu-active': state.type === 2 }" @click="tab(2)">女神</p>
        <p :class="{ 'menu-active': state.type === 1 }" @click="tab(1)">男神</p>
    </nav>
    <section id="main-find" ref="mainFind">
        <ul class="main-list f-dg f-fs14">
            <li @click="navigate(item.userId)" v-for="(item, i) in state.listData" :key="i" v-lazy:background-image="`${item.albumPictures}?x-oss-process=image/resize,m_lfit,h_530,w_392/format,webp`">
                <!--:style="`background-image:url(${item.albumPictures}`"-->
                <div class="main-list-item f-w100">
                    <p class="f-toc1">{{ item.nickname }}</p>
                    <AudioPlayer :voice="item.userVoiceDto" :key="item.userId" />
                </div>
            </li>
        </ul>
        <InfiniteScroll :handle-intersect="loadMore" v-model:no-more="state.noMore" root-selector="main-find" />
    </section>
    <VerifyPwd />
</template>
<style lang="less" scoped>
#main-find {
    // height: 86vh;
    height: 0;
    flex-grow: 1;
    overflow-y: scroll;
}
.main-list {
    /* flex-wrap: wrap; */
    grid-template-columns: repeat(5, 20%);
    padding: 16px 30px 0;
}
.main-list li {
    margin: 0 auto 16px; /*24px */
    border-radius: 10px;
    width: 196px;
    height: 265px;
    background-color: #26272c;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.main-list-item {
    height: 58px;
    border-radius: 0 0 10px 10px;
    margin-top: 207px;
    padding: 0 10px 10px;
    box-shadow: 0 -5px 25px 5px rgba(0, 0, 0, 0.1);
    background: linear-gradient(to top, rgba(0, 0, 0), rgba(0, 0, 0, 0.1));
}
.left-pic,
.left-pic img,
.main-list li img {
    width: 131px;
    height: 131px;
}
.main-menu p {
    margin: 0 20px;
    line-height: 2;
    cursor: pointer;
    color: @color-white03;
    &::after {
        content: "";
        width: 15px;
        height: 2px;
        display: block;
        background: transparent;
        border-radius: 1px;
        margin: 0px auto;
    }
}
.main-menu p.menu-active {
    color: #a283f9;
    &::after {
        background: linear-gradient(135deg, #a283f9 0%, #7b4dfd 100%);
    }
}
</style>
