<script setup>
const iframeRef = ref(null);
const iframeUrl = ref(""); //http://192.168.0.126:8085/mining?from=web
const store = appStore();
const attr = useAttrs();
const emit = defineEmits(["close"]);
watchEffect(() => {
    if (attr.src) {
        setIframeUrl(attr.src);
    }
});
onMounted(() => {
    // console.log('获取属性：===>',attr.src,attr.class);
    /*iframeRef.value.onload = function () {
        console.log('发送token:====>',store.token);
        iframeRef.value.contentWindow.postMessage(store.token, '*');
    };*/
});
function setIframeUrl(url) {
    iframeUrl.value = url;
}
</script>
<template>
    <Teleport to="body">
        <section class="iframe-wrap f-pf" v-bind="attr">
            <iframe frameborder="0" class="f-pf" :src="iframeUrl" ref="iframeRef" id="iframeRef"></iframe>
            <section class="iframe-mask" @click="emit('close')"></section>
        </section>
    </Teleport>
</template>
<style lang="less" scoped>
.iframe-wrap {
    z-index: 1001;
    width: 100%;
    height: 100%;
    margin: auto;
    iframe {
        z-index: 1001;
        top: 50%;
        width: 450px;
        height: 100%;
        max-height: 900px;
        left: 50%;
        border: 0;
        transform: translate(-50%, -50%);
        border-radius: 4px;
        // background-color: red;
    }
}
.iframe-mask {
    z-index: 1000;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}
</style>
