<script setup>
import { debounce } from "@/utils";

const router = useRouter();
const store = appStore();

const props = defineProps({
    modelValue: Boolean,
});
const show = ref(false);
const passwordEl = ref(null);
const partyNameEl = ref(null);
const myChatRoom = ref(null);
const state = reactive({
    title: "",
    showTitle: "",
    hasPassword: false,
    notifyFans: true,
    password: "",
    roomName: "",

    randomRoomNameLoading: false, //随机房间名接口loading
    animationLoading: false, //随机房间名动画loading
    createLoading: false, //创建loading
});

const column = 6;

const searchDebounce = debounce(searchFriendsInviteList, 300);
const searchState = reactive({
    nickname: "",
    list: [],
    pageIndex: 1,
    pageSize: 20,
    done: false,
});

//是否全选
const allSelected = computed(() => {
    return searchState.list.length && searchState.list.every(item => item.selected);
});

//展示多行
const showRows = computed(() => {
    return searchState.list.length > column;
});

watchEffect(() => {
    if (props.modelValue) {
        nextTick(() => {
            show.value = true;
            getOwnChat();
            searchDebounce(true);
        });
    }
});
watchEffect(() => {
    if (state.hasPassword) {
        passwordEl.value && passwordEl.value.focus();
    }
});

watchEffect(() => {
    if (state.randomRoomNameLoading) {
        state.animationLoading = true;
    }
});

//创建派对
async function createParty() {
    if (state.createLoading) return;
    const roomId = myChatRoom.value && myChatRoom.value.roomId;
    if (!roomId) {
        return Toast("请前往APP进行实名认证");
    }
    if (!state.showTitle) {
        Toast("请输入派对名称");
        partyNameEl.value && partyNameEl.value.focus();
        return;
    }
    if (state.hasPassword) {
        if (!state.password) {
            Toast("请输入密码");
            passwordEl.value && passwordEl.value.focus();
            return;
        }
        if (!/^\d{4}$/.test(state.password)) {
            Toast("请输入4位数字密码");
            passwordEl.value && passwordEl.value.focus();
            return;
        }
    }

    const { showTitle, notifyFans } = state;
    const roomStartType = {
        roomStartType: 1,
    };
    if (state.hasPassword) {
        roomStartType.roomStartType = 2;
        roomStartType.roomPassWord = state.password;
    }

    state.createLoading = true;
    try {
        const { code } = await fly.postNewOperateGameRoom({
            roomId,
            type: 2, //关闭游戏房
            roomName: showTitle,
            inviteType: notifyFans ? 1 : 2,
            ...roomStartType,
        });
        if (code === 0) {
            sendInvite({ cover: myChatRoom.value.cover, uid: store.userId, roomid: myChatRoom.value.roomId, anchor: showTitle, content: showTitle, type: "chatElem" });
            show.value = false;
            sessionStorage.create_new = "no"; //首页是否显示创建派对
            store.$patch(state => {
                state.showCreateRoomDialog = false;
            });
            router.push(`/chat-room/${roomId}`);
        }
    } catch (error) {
        Toast.fail("创建失败");
        console.log("error :>> ", error);
    }
    state.createLoading = false;
}

//全选|取消全选
function toSelectAll() {
    const bool = !allSelected.value;
    searchState.list.forEach(item => {
        item.selected = bool;
    });
}

//随机派对名称  //首次调用不触发loading动画，之后每次在动画结束之后更新数据
async function getRandomRoomName(roomId = myChatRoom.value && myChatRoom.value.roomId, isFirst = false) {
    if (roomId === null) return Toast("请前往APP进行实名认证");
    if (!roomId) return;

    if (state.randomRoomNameLoading) return;
    if (!isFirst) state.randomRoomNameLoading = true;
    try {
        const { code, data } = await fly.getRandomRoomName({ roomId });
        if (code === 0 && data && data.roomName) {
            state.title = data.roomName;
            if (isFirst && !state.showTitle) state.showTitle = state.title;
        }
    } catch (error) {
        console.log("error :>> ", error);
    }
    if (!isFirst) state.randomRoomNameLoading = false;
}

//获取个人房间
async function getOwnChat() {
    await fly.getIntoOwnChat();  //房间不存在时会创建房间
    const { code, data } = await fly.getOwnChat();
    if (code === 0 && data) {
        if (data.roomId) {
            myChatRoom.value = data;
            getRandomRoomName(data.roomId, true);
        } else if (data.roomId === null) {
            return Toast("请前往APP进行实名认证");
        }
    }
}

//一个动画周期完成
function animationiteration() {
    state.animationLoading = state.randomRoomNameLoading;
    state.showTitle = state.title;
}

//获取邀请列表
async function searchFriendsInviteList(isNew) {
    if (isNew) {
        searchState.pageIndex = 1;
        searchState.pageSize = 20;
        searchState.done = false;
    }
    if (searchState.done) return;

    const nickname = searchState.nickname;
    try {
        const { code, data } = await fly.getFriendsInviteList({
            nickname,
            pageIndex: searchState.pageIndex,
            pageSize: searchState.pageSize,
        });
        if (code === 0) {
            if (data.currPage >= data.totalPage) {
                searchState.done = true;
            }
            if (isNew) {
                searchState.list = data.list;
            } else {
                searchState.list.push(...data.list);
            }
            searchState.pageIndex++;
        }
    } catch (error) {
        console.log("error :>> ", error);
    }
}

function focus() {
    partyNameEl.value && partyNameEl.value.focus();
}

//发送分享消息邀请好友
async function sendInvite(msg) {
    searchState.list.forEach(item => {
        if (!item.id || !item.selected) return;
        const payload = {
            data: { ...msg, received: item.id },
            type: "share",
        };
        if (store.timHandle && store.timHandle.isSdkReady) {
            store.timHandle.sendC2CCustomMessage(String(item.id), JSON.stringify(payload)).then(() => {});
        }
    });
}
</script>
<template>
    <van-dialog
        class="create-room-dialog"
        v-model:show="show"
        width="400"
        :show-confirm-button="false"
        :closeOnClickOverlay="true"
        :showCancelButton="false"
        teleport="body"
        @opened="focus"
        @closed="$emit('update:modelValue', false)"
    >
        <div class="content">
            <div>语音派对</div>
            <p class="title">创建派对</p>
            <div class="party-name-wrapper">
                <input class="input-el" v-model="state.showTitle" v-focus ref="partyNameEl" placeholder="请输入派对名称" type="text" maxlength="30" />
                <div class="random-wrapper">
                    <img src="@/assets/home/ic_random.png" :class="{ loading: state.animationLoading }" alt="" @click="getRandomRoomName()" @animationiteration="animationiteration" />
                </div>
            </div>
            <div class="info">
                <p class="select" :class="{ active: state.hasPassword }" @click="state.hasPassword = !state.hasPassword">私密派对</p>
                <p class="select" :class="{ active: state.notifyFans }" @click="state.notifyFans = !state.notifyFans">通知粉丝</p>
            </div>

            <input
                class="input-el password"
                ref="passwordEl"
                v-model="state.password"
                @input="state.password = state.password.replace(/[^0-9]/g, '')"
                :class="{ hidden: !state.hasPassword }"
                placeholder="请输入4位数字密码"
                type="text"
                maxlength="4"
            />
            <div class="search-title-wrapper">
                <p class="title">邀请好友</p>
                <p class="select" :class="{ active: allSelected }" @click="toSelectAll">全选</p>
            </div>

            <div class="search-wrapper">
                <img src="@/assets/home/search-icon.png" @click="searchDebounce(true)" />
                <input class="input-el search" v-model="searchState.nickname" @input="searchDebounce(true)" placeholder="请输入玩家昵称进行搜索" type="text" maxlength="30" />
            </div>

            <ul class="invite-list" :class="{ rows: showRows }" v-scroll @scrollbottom="searchFriendsInviteList(false)" @scroll.stop="">
                <li v-for="(item, index) in searchState.list" :key="item.id + index" :class="{ selected: item.selected }" @click="item.selected = !item.selected" :title="item.nickname">
                    <img :src="item.headPortrait" alt="" />
                    <p class="f-toc1">{{ item.nickname }}</p>
                </li>
            </ul>

            <div class="create-btn" @click="createParty">创建派对</div>

            <img class="close-btn" @click="show = false" src="@/assets/follow/close.png" alt="关闭" />
        </div>
    </van-dialog>
</template>
<style lang="less" scoped>
.input-css {
    height: 40px;
    border-radius: 20px;
}
.placeholder(@color:rgba(255,255,255,0.3));

@back1: rgba(255, 255, 255, 0.048);

@keyframes rotate360 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
}
.content {
    padding: 15px 20px 20px;
    font-size: 16px;
    color: white;
    div:first-child {
        font-size: 18px;
        font-weight: 500;
        text-align: center;
    }
    .close-btn {
        position: absolute;
        right: 20px;
        top: 15px;

        .rect(14px);
        .click-btn();
    }

    .title {
        margin-top: 10px;
    }
    .input-el {
        .input-css();

        width: 100%;
        padding: 0 45px 0 15px;
        background: @back1;
        border: 1px solid rgba(255, 255, 255, 0);
        font-size: 14px;
        &:focus {
            border: 1px solid #7c4dfd8c;
        }

        &.password {
            display: block;
            transition: all ease 0.2s;
            transform-origin: top;
            &.hidden {
                height: 0;
                transform: scaleY(0);
                // visibility: hidden;
            }
        }
    }
    .party-name-wrapper {
        position: relative;
        display: block;
        margin-top: 10px;
        .random-wrapper {
            position: absolute;
            right: 10px;
            top: 50%;
            .rect(22px);

            transform: translateY(-50%);
            .click-btn();

            img {
                .rect(100%);
                object-fit: contain;
                &.loading {
                    animation: 0.46s linear 0s infinite rotate360;
                }
            }
        }
    }
    .search-title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        .title {
            margin-top: 0;
        }

        p.select {
            background: none;
            flex: none;
            color: rgba(255, 255, 255, 0.3);
            &.active {
                &::before {
                    border: none;
                    background: url("@/assets/home/selected.png") no-repeat center/contain;
                }
            }
        }
    }
    .search-wrapper {
        position: relative;
        margin-top: 10px;
        img {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            .rect(18px);
            object-fit: contain;
            cursor: pointer;
        }
    }
    .invite-list {
        margin-top: 10px;

        // display: flex;
        // flex-wrap: wrap;
        // align-items: flex-start;

        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: 5px;

        height: 65px;
        // height: 110px;
        overflow-y: auto;
        transition: all linear 0.16s;

        &.rows {
            height: 120px;
        }

        li {
            position: relative;
            // margin-right: 15px;
            margin-bottom: 25px;
            font-size: 0px;
            text-align: center;
            cursor: pointer;
            img {
                .rect(40px);
                border-radius: 50%;
                object-fit: cover;
            }
            p {
                font-size: 12px;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
                text-align: center;
            }

            &.selected {
                &::after {
                    content: "";
                    display: block;
                    .rect(16px);
                    border-radius: 50%;
                    background: url("@/assets/home/selected.png") no-repeat center/contain;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }
        }
    }
    .info {
        display: flex;
        align-items: center;
        margin-top: 15px;
        margin-bottom: 10px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.3);
    }
    p.select {
        .input-css();
        .click-btn();
        flex: 1;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: @back1;
        &:first-child {
            margin-right: 20px;
        }

        &::before {
            content: "";
            display: block;
            .rect(14px);
            margin-right: 4px;
            border-radius: 50%;
            border: 1px solid rgba(255, 255, 255, 0.3);
            box-sizing: border-box;
        }
        &.active {
            &::before {
                border: none;
                background: url("@/assets/home/selected.png") no-repeat center/contain;
            }
        }
    }

    .create-btn {
        .input-css();
        .click-btn();
        background: linear-gradient(90deg, #a283f9 0%, #7b4dfd 100%);
        text-align: center;
        line-height: 40px;
        margin-top: 20px;
        &:hover {
            background: linear-gradient(90deg, lighten(#a283f9, 2%) 0%, lighten(#7b4dfd, 2%) 100%);
        }
    }
}
</style>
<style lang="less">
.create-room-dialog {
    &.van-dialog {
        border-radius: 8px;
        background: #292940;
    }
}
</style>
