import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";
import Head from "@/components/Head.vue";
// import Kf from "@/components/ImKf.vue";

import Home from "@/views/home.vue";
import HomeRight from "@/views/home-right.vue";
import Login from "@/views/login.vue";
import Register from "@/views/register.vue";

const ChatRoom = () => import("@/views/chatroom.vue");
const Hall = () => import("@/views/hall.vue");
// const Recharge = () => import("@/views/recharge.vue");
// const Profile = () => import("@/views/profile.vue");
// const Detail = () => import("@/views/profile/detail.vue");
// const Wallet = () => import("@/views/profile/wallet.vue");
// const Grade = () => import("@/views/profile/grade.vue");
// const Dress = () => import("@/views/profile/dress.vue");
// const Vipcard = () => import("@/views/profile/vipcard.vue");
// const Nobility = () => import("@/views/profile/nobility.vue");
// const Gift = () => import("@/views/profile/gift.vue");
// const Account = () => import("@/views/profile/account.vue");

const UserProfile = () => import("@/views/user-profile/nav.vue");
const UserDetail = () => import("@/views/user-profile/detail.vue");
const UserGalaxy = () => import("@/views/user-profile/galaxy.vue");
const UserDress = () => import("@/views/user-profile/dress.vue");
const UserKnapsack = () => import("@/views/user-profile/knapsack.vue");
const UserShop = () => import("@/views/user-profile/shop.vue");
const UserVipcard = () => import("@/views/user-profile/vipcard.vue");
const UserMedal = () => import("@/views/user-profile/medal.vue");
const UserGiftWall = () => import("@/views/user-profile/gift-wall.vue");
const UserWallet = () => import("@/views/user-profile/wallet.vue");
const UserGrade = () => import("@/views/user-profile/grade.vue");
const UserNobility = () => import("@/views/user-profile/nobility.vue");
const UserAccount = () => import("@/views/user-profile/account.vue");
const UserTeenagers = () => import("@/views/user-profile/teenagers.vue");
const UserActivationCode = () => import("@/views/user-profile/activation-code.vue");
const UserSetting = () => import("@/views/user-profile/setting.vue");
const UserAbout = () => import("@/views/user-profile/about.vue");
const ParseLog = () => import("@/views/parse-log.vue");


const router = createRouter({
    history: createWebHistory("/"),
    // history: createWebHashHistory(),//无法跳转房间
    // base: "/",
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        {
            path: "/",
            name: "home",
            meta: {
                title: "小C语音_娱乐交友_语音直播_连麦互动",
                wrapClass: "customClass",
                isSidebar: true,
                // requiresAuth: true,
            },
            // component:ChatRoom
            components: {
                head: Head,
                body: Home,
                right: HomeRight,
                //   foot: Foot
            },
        },
        {
            path: "/hall",
            name: "hall",
            meta: {
                title: "大厅_小C语音",
                wrapClass: "customClass",
                isSidebar: true,
                // requiresAuth: true,
            },
            // component:ChatRoom
            components: {
                head: Head,
                body: Hall,
                right: HomeRight,
                //   foot: Foot
            },
        },
        // {
        //     path: "/kf",
        //     name: "kf",
        //     meta: {
        //         title: "客服",
        //     },
        //     components: {
        //         head: Head,
        //         body: Kf
        //     },
        // },
        // {
        //     path: "/parse-log",
        //     name: "parse-log",
        //     meta: {
        //         title: "解析日志",
        //     },
        //     components: {
        //         body: ParseLog
        //     },
        // },
        // {
        //     path: "/profile",
        //     name: "profile",
        //     meta: {
        //         title: "个人中心",
        //         wrapClass: "customClass",
        //         requiresAuth: true,
        //     },
        //     redirect: "/detail",
        //     components: {
        //         body: Profile,
        //         head: Head,
        //         //   foot: Foot
        //     },
        //     children: [
        //         {
        //             path: "/detail",
        //             name: "detail",
        //             component: Detail,
        //         },
        //         {
        //             path: "/wallet",
        //             name: "wallet",
        //             component: Wallet,
        //         },
        //         {
        //             path: "/grade",
        //             name: "grade",
        //             component: Grade,
        //         },
        //         {
        //             path: "/dress",
        //             name: "dress",
        //             component: Dress,
        //         },
        //         {
        //             path: "/vipcard",
        //             name: "vipcard",
        //             component: Vipcard,
        //         },
        //         {
        //             path: "/nobility",
        //             name: "nobility",
        //             component: Nobility,
        //         },
        //         {
        //             path: "/gift",
        //             name: "gift",
        //             component: Gift,
        //         },
        //         {
        //             path: "/account",
        //             name: "account",
        //             component: Account,
        //         },
        //     ],
        // },
        {
            path: "/user-profile",
            name: "userProfile",
            meta: {
                title: "用户资料_小C语音",
                wrapClass: "customClass",
                requiresAuth: true,
            },
            redirect: "/user-profile/detail",
            components: {
                body: UserProfile,
                head: Head,
                //   foot: Foot
            },
            children: [
                {
                    path: "/user-profile/detail",
                    name: "userDetail",
                    component: UserDetail,
                },
                {
                    path: "/user-profile/galaxy",
                    name: "userGalaxy",
                    component: UserGalaxy,
                },
                {
                    path: "/user-profile/dress",
                    name: "userDress",
                    component: UserDress,
                    meta: {
                        requiresOwner: true,
                    },
                },
                {
                    path: "/user-profile/knapsack",
                    name: "userKnapsack",
                    component: UserKnapsack,
                    meta: {
                        requiresOwner: true,
                    },
                },
                {
                    path: "/user-profile/shop",
                    name: "userShop",
                    component: UserShop,
                    meta: {
                        requiresOwner: true,
                    },
                },
                {
                    path: "/user-profile/medal",
                    name: "userMedal",
                    component: UserMedal,
                },
                {
                    path: "/user-profile/vipcard",
                    name: "userVipcard",
                    component: UserVipcard,
                },
                {
                    path: "/user-profile/gift-wall",
                    name: "userGiftWall",
                    component: UserGiftWall,
                },
                {
                    path: "/user-profile/wallet",
                    name: "userWallet",
                    component: UserWallet,
                    meta: {
                        requiresOwner: true,
                    },
                },
                {
                    path: "/user-profile/grade",
                    name: "userGrade",
                    component: UserGrade,
                    meta: {
                        requiresOwner: true,
                    },
                },
                {
                    path: "/user-profile/nobility",
                    name: "userNobility",
                    component: UserNobility,
                    meta: {
                        requiresOwner: true,
                    },
                },
                {
                    path: "/user-profile/account",
                    name: "userAccount",
                    component: UserAccount,
                    meta: {
                        requiresOwner: true,
                    },
                },
                {
                    path: "/user-profile/teenagers",
                    name: "userTeenagers",
                    component: UserTeenagers,
                    meta: {
                        requiresOwner: true,
                    },
                },
                {
                    path: "/user-profile/setting",
                    name: "userSetting",
                    component: UserSetting,
                    meta: {
                        requiresOwner: true,
                    },
                },
                {
                    path: "/user-profile/activation-code",
                    name: "userActivationCode",
                    component: UserActivationCode,
                    meta: {
                        requiresOwner: true,
                    },
                },
                {
                    path: "/user-profile/about",
                    name: "userAbout",
                    component: UserAbout,
                    meta: {
                        requiresOwner: true,
                    },
                },
            ],
        },
        {
            path: "/chat-room/:id",
            name: "chat-room",
            meta: {
                title: "聊天室_小C语音",
                wrapClass: "chat-room",
                requiresAuth: true,
            },
            // component:ChatRoom
            components: {
                head: Head,
                body: ChatRoom,
                //   foot: Foot
            },
        },
        {
            path: "/login",
            name: "login",
            meta: {
                title: "登录_小C语音",
                //requiresAuth: true
            },
            // component:Login
            components: {
                body: Login,
            },
        },
        {
            path: "/register",
            name: "register",
            meta: {
                title: "注册_小C语音",
                //requiresAuth: true
            },
            // component:Login
            components: {
                body: Register,
            },
        },
    ],
});
router.beforeEach((to, from, next) => {
    /*const body = document.body;
    if (to.meta.isbg) {
      //console.log(body)
      body.style.backgroundColor = "#fff";
    } else {
      body.removeAttribute("style");
    }*/
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    if (to.query.code) {
        sessionStorage.inviteCode = to.query.code;
    }
    if (to.meta.wrapClass) {
        document.body.removeAttribute("class");
        document.body.classList.add(to.meta.wrapClass);
    }

    // 判断是否限制
    if (to.meta.requiresAuth) {
        // console.log(sessionStorage)
        let token = localStorage.getItem("token");
        if (!token || !/\S/.test(token)) {
            const store = appStore();
            store.logout();
            next("/");
        }
        next();
    } else {
        next();
    }
    /*next()*/
});
export default router;
