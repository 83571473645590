<script setup>
import { TYPES } from "@tencentcloud/chat"; //TIM 事件、消息类型
import { diffDateTime } from "@/utils";
// import { Swiper, SwiperSlide } from "swiper/vue";
import EmojiText from "./emoji-text.vue";
import ChatImSend from "./chat-im-send.vue";
import { useEmitter } from "@/im/message-handler.js";
import { useRightMenus } from "@/utils/chat-room/menus.js";
import {decodeTextMessage} from "@/utils/emoji"
import { last } from "lodash";

const router = useRouter();

const store = appStore();
const { currentChatUser, conversationList, userId } = storeToRefs(store); //当前聊天群ID，当前房间ID
const { emitter } = useEmitter();
const { onContextMenu } = useRightMenus()
// const systemActureURL
const chatBoxElment = ref(null);
const chatActiveIndex = ref(0); //菜单栏激活的index
const chatListRef = ref(null); //消息列表dom ref

const messageList = ref([]); //消息列表
const nextReqMessageID = ref(null); //请求id
const isCompleted = ref(false); //是否全部消息
const isMessageLoading = ref(false); //请求消息数据loading
const currentUser = ref({}); //当前对话用户
const isSmooth = ref(false); //是否平滑滚动
const mySwiper = ref(null); //swiper ref
const today = new Date().toLocaleDateString();
const bigImageUrl = ref("");
const speed = ref(300); //swiper切换速度
const isEdit = ref(false);
const selectedAll = ref(false);

//好友列表状态
const friendState = reactive({
    friendsList: [],
    loading: false,
    noMore: false,
    pageSize: 10,
    currentPage: 1,
});
//消息列表 //格式化
const messageListFormat = computed(() => {
    const list = messageList.value.map((item, index, arr) => {
        if(!item.payload) return;
        if (typeof item.payload.showText === "undefined") {
            //处理显示时间
            if (index === 0) {
                item.showTime = computeTime(item.clientTime, 1);
            } else {
                item.showTime = computeTime(item.clientTime, arr[index - 1].clientTime);
            }
            //处理盲盒消息
            if (item.cloudCustomData) {
                if (2 === checkBlindMsg(item.cloudCustomData)) {
                    item.isHiddenMsg = true; //隐藏盲盒消息
                }
            }
            //处理消息类型
            if (item.type === "TIMTextElem") {
                item.payload.showText = item.payload.text;
            } else if (item.type === "TIMCustomElem") {
                const data = JSON.parse(item.payload.data);
                item.payload.parseData = data;
                if (data.msg){
                    const subTitle = data.titleTwo ? `<h4>${data.titleTwo}</h4>` : '';
                    item.payload.showText = `<h3>${data.title}</h3> ${subTitle} <i class="f-fs12 f-ce7e">${data.msg}</i>`;
                } 
                else if (data.data) item.payload.showText = data.data.text;
                // console.log('自定义消息：',item,data);
                //心网消息
                if (data.mType) {
                    if (data.mType === "ctUserRelationshipApply") {
                        item.payload.showText = `${data.nickname}申请与你连接心网，达成${data.relationshipName}关系`;
                    } else if (data.mType === "ctUserRelationshipRefuse") {
                        item.payload.showText = "你的心网连接申请已被拒绝";
                    }
                }
                if (data.type === "share") {
                    item.payload.showText = "[分享消息]";
                }
            } else if (item.type === "TIMImageElem") {
                item.payload.showText = "[图片]";
                item.payload.thumbnail = item.payload.imageInfoArray.find(info => info.sizeType === 3);
            } else if (item.type === "TIMSoundElem") {
                item.payload.showText = "[语音消息暂不支持，请前往APP查看]";
            } else if (item.type === "TIMVideoFileElem") {
                // console.table(item.payload);
                item.payload.showText = "[视频消息暂不支持，请前往APP查看]";
            }
        }
        return item;
    });
    // console.info('格式化消息：',list);
    return list;
});

function computeTime(now, old) {
    if (now && old && now - old > 5 * 60) {
        let date = new Date(now * 1000);
        let localeDate = date.toLocaleDateString();
        if (localeDate === today) localeDate = "";
        else localeDate += " ";
        return localeDate.replace(/\//g, "-") + date.toLocaleTimeString().substring(0, date.toLocaleTimeString().lastIndexOf(":"));
    } else return "";
}

//检查盲盒消息  0.非盲盒消息 ，1盲盒消息 ， 2盲盒消息并隐藏
function checkBlindMsg(cloudCustomData) {
    try {
        const cloudCustomDataParse = JSON.parse(cloudCustomData);
        if (cloudCustomDataParse.hiddenUser === userId.value) return 2;
        if (cloudCustomDataParse.hiddenUser) return 1;
    } catch (error) {
        console.log("error :>> ", error);
    }
    return 0;
}

//tim会话列表
const C2CList = computed(() => {
    return conversationList.value.map((item, index) => {
        // if (item.conversationID === "C2C12") return null; //去掉心网消息
        return userInfoFormat(item);
    });
});
//私信会话列表
const privateC2CList = ref([]);
watch(
    () => C2CList.value,
    C2C => {
        privateC2CList.value = C2C.filter(item => {
            if(item && item.uid!=kfId){//屏蔽客服消息
                item.selected = false;
                return item && !(Number(item.uid) < 10000);
            }
        });
        // console.log('私信：====>',privateC2CList.value);
    },
    { immediate: true }
);
//通知会话列表
const noteC2CList = computed(() => {
    const removeId = ['10','13']
    return C2CList.value.filter(item => {
        if(!removeId.includes(item.uid)){//屏蔽客服
            return item && Number(item.uid) < 10000
        }
    });
});

//会话未读数量
const privateUnReadCount = computed(() => {
    return privateC2CList.value.reduce((total, item) => {
        return total + item.unreadCount;
    }, 0);
});
//通知未读数量
const noteUnReadCount = computed(() => {
    return noteC2CList.value.reduce((total, item) => {
        return total + item.unreadCount;
    }, 0);
});

//监听列表index改变,修改当前聊天用户，
watch(chatActiveIndex, async (newChatActiveIndex, oldValue) => {
    if (currentChatUser.value && currentUser.value && currentUser.value.conversationID) {
        currentChatUser.value = null;
        return;
    }
    currentUser.value = {};
    if (newChatActiveIndex === 0 && privateC2CList.value.length) currentUser.value = privateC2CList.value[0];
    if (newChatActiveIndex === 1 && noteC2CList.value.length) currentUser.value = noteC2CList.value[0];
    if (newChatActiveIndex === 2) {
        if (friendState.friendsList.length) currentUser.value = friendState.friendsList[0];
        await getFriendsListNew(true);
        if (friendState.friendsList.length) currentUser.value = friendState.friendsList[0];
        // //获取到新列表后恢复聚焦
        // const user = activeList.find(item => {
        //     return currentUser.value.conversationID === item.conversationID;
        // });
        // if (user) {
        //     currentUser.value = user;
        // } else if (friendState.friendsList.length) {
        //     currentUser.value = friendState.friendsList[0];
        // } else currentUser.value = {};
    }
    //修复tim异步获取用户头像和昵称导致无法显示的bug
    if (oldValue === 2) {
        store.conversationList = [...store.conversationList];
    }
});

//监听切换对话用户
watch(
    () => currentUser.value.conversationID,
    newVal => {
        //请求聊天记录
        isCompleted.value = false;
        nextReqMessageID.value = null;
        messageList.value = [];
        // const uid = currentUser.value.uid;
        if (!newVal) return;
        nextTick(() => {
            getMessageList(newVal);
        });
    }
);
//组件监听TIM私聊消息事件
emitter.once(TYPES.CONV_C2C, ({ message }) => {
    const types = [TYPES.MSG_TEXT,TYPES.MSG_IMAGE,TYPES.MSG_CUSTOM,TYPES.MSG_AUDIO,TYPES.MSG_VIDEO];
    // 私聊消息
    if (types.includes(message.type)) {
        if (message.conversationID === currentUser.value.conversationID) {
            //针对公会邀请特别处理，历史状态重置
            const lastData = messageList.value.at(-1)
            const messageLen = messageList.value.length - 1;
            const data = JSON.parse(lastData.payload.data);
            console.log(data.mType);
            if(['guildInvitesToJoin','guildUserTypeToSpecial','guildUserTypeToCooperation'].includes(data.mType)){
                const payload = {}
                if(data.msg){
                    const subTitle = data.titleTwo ? `<h4>${data.titleTwo}</h4>` : '';
                    payload.showText = `<h3>${data.title}</h3> ${subTitle} <i class="f-fs12 f-ce7e">${data.msg}</i>`;
                    payload.parseData = {...data,status:1};
                }
                messageList.value.splice(messageLen,1,{...lastData,payload});
                console.log('当前数据',messageList.value,lastData);
            }
            
            messageList.value.push(message);
            scrollBottom(true);
        }
    }else{
        console.log('私聊消息',message.type);
    }
});

onBeforeMount(() => {
    //聚焦未读消息会话
    if (!currentChatUser.value) {
        if (privateUnReadCount.value && privateC2CList.value.length) {
            currentChatUser.value = privateC2CList.value[0];
        } else if (noteUnReadCount.value && noteC2CList.value.length) {
            currentChatUser.value = noteC2CList.value[0];
        }
    }

    //点击私聊进入 //聚焦当前私聊用户会话
    if (currentChatUser.value) {
        if (store.timHandle && store.timHandle.isSdkReady) {
            //获取会话，没有会话会新生成会话并触发conversationList更新
            speed.value = 0;
            store.timHandle.tim
                .getConversationProfile(currentChatUser.value.conversationID)
                .then(imResponse => {
                    currentUser.value = userInfoFormat(imResponse.data.conversation);
                    let index = noteC2CList.value.findIndex(item => {
                        return item.conversationID === currentChatUser.value.conversationID;
                    });
                    if (index !== -1) {
                        switchNav((chatActiveIndex.value = 1));
                    }
                })
                .catch(error => {
                    //未获取到会话
                    console.log("error:", error);
                })
                .finally(() => {
                    speed.value = 300;
                });
        }
    }
    //点击消息进入  //聚焦第一个会话
    else {
        if (privateC2CList.value.length) currentUser.value = privateC2CList.value[0];
    }
});

//----监听用户活动事件，更新未读消息
onMounted(() => {
    chatBoxElment.value.addEventListener(["click"], readMsg);
    // chatBoxElment.value.addEventListener(["mousemove"], readMsg);
    chatBoxElment.value.addEventListener(["keydown"], readMsg);
});

onBeforeUnmount(() => {
    chatBoxElment.value.removeEventListener(["click"], readMsg);
    // chatBoxElment.value.removeEventListener(["mousemove"], readMsg);
    chatBoxElment.value.removeEventListener(["keydown"], readMsg);
});

function readMsg() {
    nextTick(() => {
        if (!currentUser.value) return;
        const activeUser = conversationList.value.find(item => {
            return item.conversationID && item.conversationID === currentUser.value.conversationID;
        });
        if (!activeUser || !activeUser.unreadCount) return;
        if (!store.timHandle || !store.timHandle.tim || !currentUser.value.conversationID) return;
        store.timHandle.tim.setMessageRead({ conversationID: currentUser.value.conversationID }); // 将某会话下所有未读消息已读上报
    });
}
//----

//提取会话信息中的用户信息并格式化
function userInfoFormat(conversation) {
    // console.table('会话消息:===>',conversation);
    let temp = {};
    temp.unreadCount = conversation.unreadCount;
    temp.nick = conversation.userProfile.nick || "";
    temp.uid = conversation.userProfile.userID;
    temp.avatar = conversation.userProfile.avatar || "";
    temp.conversationID = conversation.conversationID;
    temp.isPinned = conversation.isPinned || false;
    temp.remark = conversation.remark;
    temp.lastTime = diffDateTime(conversation.lastMessage.lastTime * 1000);
    if (conversation.lastMessage.type === "TIMTextElem") {
        temp.lastText = conversation.lastMessage.payload.text;
        if (conversation.lastMessage.cloudCustomData && checkBlindMsg(conversation.lastMessage.cloudCustomData)) {
            temp.lastText = "[盲盒消息]";
        }
    } else if (conversation.lastMessage.type === "TIMCustomElem") {
        const data = JSON.parse(conversation.lastMessage.payload.data);
        temp.lastText = data.msg || (data.data && data.data.text) || "";
        if (data.type === "share") temp.lastText = "[分享消息]";
    } else if (conversation.lastMessage.type === "TIMImageElem") {
        temp.lastText = "[图片]";
    } else if (conversation.lastMessage.type === "TIMSoundElem") {
        temp.lastText = "[语音]";
    } else if (conversation.lastMessage.type === "TIMVideoFileElem") {
        temp.isPlay = false;
        temp.lastText = "[视频]";
    }
    return temp;
}
//浏览大图
function viewBigImage(imageInfoArray) {
    if (!imageInfoArray) return;
    const imageInfo = imageInfoArray.find(info => info.sizeType === 1);
    if (imageInfo) {
        bigImageUrl.value = imageInfo.url;
    }
}

//关闭聊天框
function closeDialog() {
    store.$patch(state => {
        state.isShowChatBox = false;
        state.currentChatUser = null;
    });
}

//聊天记录滚动到底部
function scrollBottom(bool) {
    if (typeof bool === "boolean") isSmooth.value = bool; //是否平滑滚动
    nextTick(() => {
        if (chatListRef.value && chatListRef.value.offsetHeight) {
            chatListRef.value.scrollTop = chatListRef.value.scrollHeight;
        }
        isSmooth.value = false;
    });
}
//聊天记录向上滚动到顶部获取新数据后滚动到原位置
function scrollOldSize() {
    if (!chatListRef.value) return;
    const size = chatListRef.value.scrollHeight;
    nextTick(() => {
        chatListRef.value.scrollTop = chatListRef.value.scrollHeight - size;
    });
}

//消息发送
function onSendMsg(msg) {
    messageList.value.push(msg);
    setTimeout(() => {
        scrollBottom(true);
    }, 50);
}
//消息发送成功  //取消loading
function onSuccess(msg) {
    let index = messageList.value.lastIndexOf(msg);
    if (index !== -1) {
        //触发响应式
        const status = messageList.value[index].status;
        messageList.value[index].status = "";
        messageList.value[index].status = status;
        // messageList.value[index] = {...msg};
    }
}
function onSlideChange(index) {
    chatActiveIndex.value = index;
}
function switchNav(index) {
    mySwiper.value.swipeTo(index);
}

//全选私信会话
function selectChat() {
    // const list = privateC2CList.value;
    selectedAll.value = !selectedAll.value;
    privateC2CList.value.map(el => {
        el.selected = selectedAll.value;
        return el;
    });
}
//批量删除会话
async function deleteChatList() {
    const conversationIDList = privateC2CList.value.map(el => el.selected && el.conversationID).filter(Boolean);
    if (!conversationIDList[0]) {
        return Toast("请选择删除对象！");
    }
    // console.log(privateC2CList.value,conversationIDList);return;
    await store.timHandle.deleteConversation(conversationIDList);
    isEdit.value = false;
}
//激活会话
function activeChat(user) {
    currentUser.value = user;
}

//获取会话消息列表
async function getMessageList(id, pullRefresh = false) {
    if (!store.timHandle) return;
    if (pullRefresh && isCompleted.value) return;
    isMessageLoading.value = true;
    const resp = await store.timHandle.getMessageList({
        conversationID: id,
        ...(pullRefresh ? { nextReqMessageID: nextReqMessageID.value } : {}),
        count: 15,
    });
    // console.log("resp.messageList :>> ", resp.messageList);
    if (pullRefresh) {
        messageList.value.unshift(...resp.messageList);
    } else {
        messageList.value = resp.messageList;
    }
    nextReqMessageID.value = resp.nextReqMessageID;
    isCompleted.value = resp.isCompleted;
    isMessageLoading.value = false;
    if (!pullRefresh) {
        scrollBottom();
    } else {
        // if (isCompleted.value) Toast("到顶了");
        scrollOldSize();
    }
}

//获取好友列表
async function getFriendsListNew(isFirst) {
    //重新加载首页
    if (isFirst) {
        // friendState.friendsList = [];
        friendState.loading = false;
        friendState.noMore = false;
        friendState.pageSize = 10;
        friendState.currentPage = 1;
    }
    if (friendState.noMore) return;
    friendState.loading = true;

    try {
        let response = await fly.getFriendsListNew({
            pageSize: friendState.pageSize,
            currentPage: friendState.currentPage,
        });
        if (response && response.code === 0) {
            const { list, currPage, totalPage } = response.data;
            const listFormat = list.map(item => {
                item.nick = item.nickname;
                item.uid = item.id;
                item.avatar = item.headPortrait;
                item.conversationID = "C2C" + item.id;
                return item;
            });

            friendState.currentPage++;
            if (currPage >= totalPage) {
                friendState.noMore = true;
            }

            if (isFirst) friendState.friendsList = listFormat;
            else friendState.friendsList.push(listFormat);
        }
    } catch (error) {
        console.log("error :>> ", error);
    }

    friendState.loading = false;
}

//通过分享连接跳转到房间
function intoRoom(roomId) {
    if (roomId && store.currentRoomID !== roomId) {
        const { href } = router.resolve(`/chat-room/${roomId}`);
        window.open(href, "_self");
    }
}
</script>

<template>
    <section class="chat-box" ref="chatBoxElment" v-drag-window>
        <div class="left">
            <nav class="bar drag">
                <p :class="{ active: chatActiveIndex === 0, newMsg: privateUnReadCount }" @click.stop="switchNav(0)">私信</p>
                <p :class="{ active: chatActiveIndex === 1, newMsg: noteUnReadCount }" @click.stop="switchNav(1)">通知</p>
                <p :class="{ active: chatActiveIndex === 2 }" @click.stop="switchNav(2)">好友</p>
            </nav>
            <van-swipe ref="mySwiper" @change="onSlideChange" :autoplay="0" :show-indicators="false" :loop="false">
                <van-swipe-item>
                    <ul class="chat-list">
                        <p class="no-data" v-if="!privateC2CList.length">暂无私信</p>

                        <li
                            v-for="(item, index) in privateC2CList"
                            :class="{ active: item.uid == currentUser.uid }"
                            :key="item.conversationID"
                            @click="activeChat(item)"
                            @contextmenu="onContextMenu($event, item)"
                            class="f-aic f-pr"
                        >
                            <p class="f-pa chat-list-top-icon" title="置顶" v-if="item.isPinned">
                                <van-icon name="award" size="24" color="#a283f9"/>
                            </p>
                            <van-icon
                                v-if="item.selected || isEdit"
                                :color="item.selected ? '#a283f9' : ''"
                                :name="item.selected ? 'passed' : 'circle'"
                                size="24"
                                @click.stop="item.selected = !item.selected"
                            />
                            &ensp;
                            <div class="head-img" :style="`background-image:url(${item.avatar}?x-oss-process=image/resize,m_lfit,h_54,w_54)`" @click.stop="store.previewUserInfo(item.uid)"></div>
                            <div class="chat-info">
                                <p :title="item.nick">{{ item.remark || item.nick }}</p>
                                <p>{{ decodeTextMessage(item.lastText) }}</p>
                            </div>
                            <div class="time-info">
                                <p>{{ item.lastText ? item.lastTime : " " }}</p>
                                <p class="unread" v-show="item.unreadCount">{{ item.unreadCount < 100 ? item.unreadCount : "99+" }}</p>
                            </div>
                        </li>
                    </ul>
                    <div class="f-fx f-aic f-pa chat-list-handler f-w100 f-csp" v-if="privateC2CList[0]">
                        <van-icon title="全选" :color="selectedAll ? '#a283f9' : ''" :name="selectedAll ? 'passed' : 'circle'" size="24" @click.stop="selectChat" />
                        <div class="f-fx1 f-fx f-aic chat-list-handler-btn">
                            <van-icon title="编辑" name="edit" size="24" @click.stop="isEdit = !isEdit" />
                            <van-icon title="删除" name="delete-o" size="24" @click.stop="deleteChatList" v-show="isEdit" />
                        </div>
                    </div>
                </van-swipe-item>
                <van-swipe-item>
                    <ul class="chat-list">
                        <p class="no-data" v-if="!noteC2CList.length">暂无通知</p>
                        <li v-for="(item, index) in noteC2CList" :class="{ active: item.uid == currentUser.uid }" :key="item.conversationID" @click="activeChat(item)">
                            <div class="head-img" :style="`background-image:url(${item.avatar}?x-oss-process=image/resize,m_lfit,h_54,w_54)`"></div>
                            <div class="chat-info">
                                <p :title="item.nick">{{ item.nick }}</p>
                                <p>{{ item.lastText }}</p>
                            </div>
                            <div class="time-info">
                                <p>{{ item.lastTime }}</p>
                                <p class="unread" v-show="item.unreadCount">{{ item.unreadCount < 100 ? item.unreadCount : "99+" }}</p>
                            </div>
                        </li>
                    </ul>
                </van-swipe-item>
                <van-swipe-item>
                    <ul id="friends-list" class="chat-list" v-scroll @scrollbottom="getFriendsListNew()" @scroll.stop="">
                        <p class="no-data" v-if="!friendState.friendsList.length && !friendState.loading">暂无数据</p>
                        <li v-for="(item, index) in friendState.friendsList" :class="{ active: item.uid === currentUser.uid }" :key="item.id" @click="activeChat(item)">
                            <div class="head-img" :style="`background-image:url(${item.headPortrait}?x-oss-process=image/resize,m_lfit,h_54,w_54)`" @click.stop="store.previewUserInfo(item.uid)"></div>
                            <div class="chat-info friends" :title="item.nickname">
                                <p>{{ item.remarksName || item.nickname }}</p>
                            </div>
                        </li>

                        <van-loading class="f-tac" v-if="friendState.loading" size="24" type="spinner" color="#999" />

                        <!-- <InfiniteScroll :handle-intersect="getFriendsListNew" :noMore="friendState.noMore" root-selector="friends-list" /> -->
                    </ul>
                </van-swipe-item>
            </van-swipe>
        </div>
        <div class="right f-pr chat-box-right">
            <div class="bar drag">
                <p class="nick">{{ currentUser.remark || currentUser.remarksName || currentUser.nick || "" }}</p>
                <div class="close" @click.stop="closeDialog">
                    <img src="@/assets/chat/close.png" alt="" />
                </div>
            </div>
            <div v-scroll @scrolltop="getMessageList(currentUser.conversationID, true)" class="content" ref="chatListRef" :style="`scroll-behavior:${isSmooth ? 'smooth' : 'auto'}`">
                <ul>
                    <van-loading v-if="isMessageLoading" size="24" type="spinner" color="#999" />
                    <li v-for="(item, index) in messageListFormat" :key="item + index" :class="{ myself: userId == item.from, hidden: item.isHiddenMsg }">
                        <div class="time" v-if="item.showTime">{{ item.showTime }}</div>
                        <div class="avatar" :style="`background-image:url(${item.avatar}?x-oss-process=image/resize,m_lfit,h_60,w_60)`"></div>
                        <div class="img-wrapper" v-if="item.type === 'TIMImageElem'">
                            <img :src="item.payload.thumbnail.url" :height="item.payload.thumbnail.height || ''" alt="" @click="viewBigImage(item.payload.imageInfoArray)" />
                        </div>
                        <div class="voice-wrapper" v-else-if="item.type === 'TIMSoundElem'">
                            <AudioPlayer :voice="{ voice: item.payload.url, voiceDuration: item.payload.second ? '' + item.payload.second + '″' : '',status:1 }" :key="item.payload.uuid" />
                        </div>
                        <div class="voice-wrapper video-wrapper" v-else-if="item.type === 'TIMVideoFileElem'">
                            <video :src="item.payload.videoUrl" controls v-if="item.payload.isPlay"></video>
                            <div class="video-place f-pr" v-else>
                                <img :src="item.payload.thumbUrl||'https://oss.cdddian.com/im/2023-12-29/ef5746895ad2495a8945f5b74169e34b259486.png'" title="点击播放" @click="item.payload.isPlay=true">
                            </div>
                        </div>
                        <div class="share-wrapper"
                            v-else-if="item.type === 'TIMCustomElem' && item.payload.parseData && item.payload.parseData.type === 'share' && item.payload.parseData.data.type === 'chatElem'"
                        >
                            <div>
                                <img :src="item.payload.parseData.data.cover" alt="" />
                                <p>
                                    我在
                                    <span>{{ item.payload.parseData.data.content }}</span>
                                    听声音，快来一起听吧
                                </p>
                            </div>
                            <div @click="intoRoom(item.payload.parseData.data.roomid)">
                                <p>进入房间</p>
                                <img src="@/assets/profile/arrow.png" alt="" />
                            </div>
                        </div>
                        <p v-else>
                            <EmojiText :text="item.payload.showText || '[暂不支持该消息类型，请前往APP查看]'" :other="item.payload.parseData"></EmojiText>
                        </p>

                        <van-loading v-if="item.status === 'unSend'" size="24" color="#999" />
                    </li>
                </ul>
            </div>
            <ChatImSend v-if="Number(currentUser.uid) > 9 && currentUser.conversationID !== 'C2C12'" :currentUserId="+currentUser.uid" @onSendMsg="onSendMsg" @onSuccess="onSuccess" />
            <!-- :currentUser="currentUser" -->
        </div>
        <Teleport to="body">
            <transition name="fade">
                <PhotoViewer v-if="bigImageUrl" v-model:bigImageUrl="bigImageUrl"></PhotoViewer>
            </transition>
        </Teleport>
    </section>
</template>
<style lang="less" scoped>
.scrollbar();
.chat-list-handler {
    bottom: 0;
    background-color: #ffffff12;
    padding: 10px 0 10px 10px;
    opacity: 0;
    transition: opacity 0.3s;
    &:hover {
        opacity: 1;
    }
}
.chat-list-top-icon{
    left:0;
    top:0;
}
.chat-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    z-index: 110;

    display: flex;
    align-items: top;

    border-radius: 8px;
    background: #292940;
    background: linear-gradient(141deg, #150d3b 0%, #05071c 100%);

    font-size: 18px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.4);

    overflow: hidden;

    box-shadow: 0px 2px 12px 0px rgba(5, 7, 28, 0.7);
    // border: 1px solid rgba(255, 255, 255, 0.1);

    .left {
        width: 330px;
        .bar {
            display: flex;
            align-items: center;
            height: 70px;
            padding: 10px;
            cursor: move;
            > p {
                padding: 5px;
                margin: 0 20px;
                cursor: pointer;
                &.active {
                    color: #ffffff;
                    &.newMsg {
                        &::after {
                            background-color: #ff073d;
                        }
                    }
                }
                &.newMsg {
                    position: relative;
                    &::after {
                        content: "";

                        position: absolute;
                        top: 10px;
                        left: 44px;

                        display: block;
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        background-color: #ff073d75;
                    }
                }
            }
        }
        // .swiper-slide {
        // }
        .chat-list {
            height: 600px;
            // background-color: #1b1d38;
            background-color: #1b1d3836;
            overflow-y: scroll;
            overflow-y: overlay; //safari不支持
            // scrollbar-gutter: stable;
            .no-data {
                margin-top: 10px;
                font-size: 14px;
                text-align: center;
            }
            li {
                display: flex;
                padding: 12px 20px 12px 10px;

                cursor: pointer;

                &.active {
                    // background: #2b2d46;
                    background: #2b2d46a8;
                }
                .head-img {
                    flex: none;
                    width: 54px;
                    height: 54px;
                    margin-right: 10px;
                    border-radius: 50%;
                    background: no-repeat center center/cover;
                }
                .chat-info {
                    flex: auto;
                    &.friends {
                        display: flex;
                        align-items: center;
                    }

                    p {
                        .single-line();
                        width: 140px;

                        font-size: 16px;
                        color: rgba(255, 255, 255, 0.3);
                        &:first-child {
                            font-size: 16px;
                            color: #ffffff;
                        }
                    }
                }
                .time-info {
                    flex: none;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    max-width: 90px;
                    text-align: right;

                    font-size: 16px;
                    color: rgba(255, 255, 255, 0.3);

                    .unread {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 20px;
                        height: 20px;

                        font-size: 12px;
                        color: white;

                        background: #ff073d;
                        border-radius: 50%;
                    }
                }
            }
            .van-loading:first-child {
                margin-top: 20px;
            }
            &:hover ~ div {
                opacity: 1;
            }
        }
    }
    .right {
        width: 690px;
        display: flex;
        flex-direction: column;
        .bar {
            display: flex;
            align-items: center;
            height: 70px;
            width: 100%;
            padding: 10px 20px;
            border-left: 1px rgba(255, 255, 255, 0.07) solid;
            cursor: move;
            .nick {
                font-size: 16px;
                color: #ffffff;
            }

            .close {
                position: absolute;
                right: 10px;
                top: 10px;
                padding: 10px;
                font-size: 0;
                cursor: pointer;
                img {
                    width: 14px;
                    height: 14px;
                }
            }
        }
        .content {
            flex: auto;
            // background-color: #0d0e1f;
            background-color: #0d0e1fb3;

            // background: url(@/assets/abg.jpg) no-repeat center center/cover;
            height: 0px;
            overflow-y: scroll;
            scroll-behavior: smooth;
            ul {
                padding: 20px;
                font-size: 16px;
                line-height: 1.5;
                overflow-y: scroll;

                .van-loading {
                    text-align: center;
                }
                li {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    margin-top: 10px;

                    .time {
                        text-align: center;
                        width: 100%;
                        margin-top: 5px;
                    }
                    .avatar {
                        flex: none;

                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        background: no-repeat center center/cover;
                        margin-right: 20px;
                    }
                    > p {
                        max-width: 80%;

                        position: relative;

                        display: inline-flex;
                        align-items: center;
                        flex-wrap: wrap;

                        min-height: 50px;
                        margin-top: 5px;
                        padding: 13px 20px;
                        border-radius: 8px;
                        background: rgba(255, 255, 255, 0.1);

                        color: rgba(255, 255, 255, 0.9);

                        &::before {
                            content: "";
                            display: block;
                            position: absolute;
                            top: 14px;
                            left: 0;
                            height: 0;
                            width: 0;

                            box-sizing: content-box;
                            border-right: 10px solid rgba(255, 255, 255, 0.1);
                            border-top: 10px solid transparent;
                            border-bottom: 10px solid transparent;
                            background-color: transparent;

                            transform: translateX(-100%);
                        }
                    }
                    .img-wrapper {
                        img {
                            border-radius: 8px;
                            min-height: 60px;
                            min-width: 60px;
                            max-height: 200px;
                            max-width: 200px;
                            object-fit: contain;
                            cursor: zoom-in;
                        }
                    }
                    .voice-wrapper {
                        margin: 15px 0;
                        :deep(.sound) {
                            padding: 0px 12px;
                            height: 30px;
                            width: unset;
                            min-width: 100px;
                            border: none;
                            background: rgba(255, 255, 255, 0.1);
                            color: white;
                            font-size: 14px;
                            &::before {
                                margin: 0 5px;
                            }
                        }
                    }
                    .video-wrapper{
                        video,img{
                            border-radius: 8px;
                            min-height: 60px;
                            min-width: 60px;
                            max-height: 200px;
                            max-width: 200px;
                            object-fit: contain;
                            // cursor: zoom-in;
                        }
                        .video-place{
                            &::before{
                                content:'';
                                position: absolute;
                                width: 32px;
                                height: 32px;
                                top: 50%;
                                left: 50%;
                                transform: translate(-16px,-16px);
                                background:url(@/assets/icon-play.png) no-repeat center center/cover;
                            }
                            transition:transform 1s ease-in;
                            &:hover .video-place{
                                transform: scale(1.2);
                            }
                        }
                    }
                    .share-wrapper {
                        padding: 10px;
                        border-radius: 8px;
                        background: rgba(255, 255, 255, 0.1);
                        max-width: 50%;
                        > div {
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            p {
                                word-break: break-all;
                                span {
                                    color: @color-gold;
                                }
                            }
                            &:first-child {
                                img {
                                    .rect(60px);
                                    object-fit: cover;
                                    border-radius: 8px;
                                    margin-right: 10px;
                                }
                            }
                            &:last-child {
                                border-top: 1px solid rgba(255, 255, 255, 0.1);
                                padding-top: 5px;
                                margin-top: 10px;
                                justify-content: space-between;
                                cursor: pointer;
                                img {
                                    width: 6px;
                                }
                            }
                        }
                    }
                    .van-loading {
                        align-self: center;
                        margin: 0 10px;
                    }
                    &.hidden {
                        display: none;
                    }
                    &.myself {
                        flex-direction: row-reverse;
                        .avatar {
                            margin-right: 0;
                            margin-left: 20px;
                        }
                        > p {
                            background: linear-gradient(90deg, #a283f9, #7b4dfd);
                            word-break: break-all;
                            max-width: 70%;
                            &::before {
                                display: none;
                            }
                            &::after {
                                content: "";
                                display: block;
                                position: absolute;
                                top: 14px;
                                right: 0;
                                height: 0;
                                width: 0;

                                box-sizing: content-box;
                                border-left: 10px solid #7b4dfd;
                                border-top: 10px solid transparent;
                                border-bottom: 10px solid transparent;
                                background-color: transparent;

                                transform: translateX(100%);
                            }
                        }
                        .voice-wrapper {
                            :deep(.sound) {
                                flex-direction: row-reverse;
                                &::before {
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.chat-list-handler-btn {
    justify-content: flex-end;
    i {
        margin: 0 20px;
    }
}
.fade-scale-enter-active,
.fade-scale-leave-active {
    transition: all 0.2s;
}
.fade-scale-enter-from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.9);
}
.fade-scale-leave-to {
    opacity: 0;
}
</style>
