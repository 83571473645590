<script setup>
import {getUrlParams} from '@/utils'
const blindBoxDialog = defineAsyncComponent(() => import("@/views/blind-box/blind-box-dialog.vue"));

const showBlindBoxDialog = ref(false);

const store = appStore();
const router = useRouter();
const swiperData = ref([]);
const myChatRoom = ref(null);
const showDialog = ref(false);
const state = reactive({
    listData: [],
    rankingData: {
        top: [],
        body: [],
    },
    page: 0,
    type: 1,
    total: 30,
    noMore: false,
    isShow: false,
    jumpUrl: "",
    rankingIndex: 0,
    subIndex: 1,
    dateIndex: 1,
});
async function getBanner() {
    try {
        const { data } = await fly.getBanner({advertisingType:5,locationType:1});
        swiperData.value = data;
    } catch (error) {
        console.log("错误信息：", error);
    }
}
async function navigate({ roomId }) {//, chatId
    if (roomId === null) {
        Toast("请前往APP进行实名认证");
        return;
    }
    // if (!roomId) return;
    // const chatType = [25,27]
    // if (!roomId || (chatId && chatType.includes(chatId))) return;
    const {
        data: { isAccess },
        code,
    } = await fly.checkRoomAccessNew({ roomId });
    if (!isAccess) {
        store.enterRoomId = roomId;
        store.openVerifyPwdDialog = true;
        return false;
    }
    router.push(`/chat-room/${roomId}`);
}
async function loadMore() {
    if (state.noMore) {
        //||!store.token
        // state.noMore = true
        return false;
    }
    ++state.page;
    try {
        const {
            data: { list, currPage, totalPage },
        } = await fly.getPartyList({
            pageIndex: state.page,
            pageSize: state.total,
            type: 2,
        });
        // console.log(list);
        if (currPage >= totalPage || !list[0]) {
            state.noMore = true;
        }
        if (list && list[0]) {
            state.listData.push(...list);
        }
    } catch (error) {
        console.log("错误信息：", error);
    }
}
function tabRanking(index) {
    state.rankingIndex = index;
    if(index === 0){
        sensationRanking();
    }
    if (index === 1) {
        giftRanking();
    }
    if (index === 2) {
        partyRanking();
    }
}
async function sensationRanking() {
    try {
        const {
            data: { dataList },
        } = await fly.getRankingListNew({
            type: 4,
            listType: 2,
        });
        state.rankingData.top = dataList.slice(0, 3);
        state.rankingData.body = dataList.slice(3);
    } catch (error) {
        console.log("error :>> ", error);
    }
}
async function giftRanking(type = 1) {
    const { data } = await fly.getGiftWallRankingList({
        type,
    });
    state.rankingData.top = data.slice(0, 3);
    state.rankingData.body = data.slice(3);
    state.subIndex = type;
}
async function partyRanking(type = 1) {
    const {
        data: { dataList },
    } = await fly.getRankingList({
        type,
        listType: 3,
    });
    dataList.map(el => {
        el.nickName = el.theme;
        el.headPortrait = el.cover;
        return el;
    });
    state.rankingData.top = dataList.slice(0, 3);
    state.rankingData.body = dataList.slice(3);
    state.dateIndex = type;
}
function getMyRoomId() {
    fly.getIntoOwnChat();
}
function jumpToActivity({ type, roomId,userId, jumpUrl,playId }) {
    if(jumpUrl && playId && !store.agreePlayNotice.activity){//显示玩法公告
        store.agreePlayNotice.type=3
        store.openPlayNoticeDialog = true
        return;
    }
    switch (type) {
        case 2://纯链接
            state.jumpUrl = `${jumpUrl}?from=web&token=${store.token}`;
            break;
        case 4://个人资料
            store.previewUserInfo(userId);
            break;
        case 5://活动
            state.jumpUrl = `${jumpUrl}?from=web&token=${store.token}&activityId=${playId}`; //&timestamp=${Date.now()}
            break;
        case 3://房间
            navigate({roomId});
            break;
    }
}
async function getOwnChat() {
    const { data } = await fly.getOwnChat();
    // console.log(data);
    myChatRoom.value = data;
}
getOwnChat();
getBanner();
// if(store.token){
sensationRanking();
// }
</script>
<template>
    <van-swipe class="swiper-card" :autoplay="3200" :show-indicators="false">
        <van-swipe-item v-for="(item, index) in swiperData" :key="index" @click="jumpToActivity(item)">
            <img :src="`${item.imageUrl}?x-oss-process=image/resize,m_lfit,h_76,w_252/format,webp`" class="swiper-slide-img" />
        </van-swipe-item>
    </van-swipe>
    <section class="activity-card" @click="showBlindBoxDialog = !showBlindBoxDialog">
        <img src="@/assets/blind-box/blind-box.png" />
    </section>
    <section class="f-dfc home-right-rank" @click="state.isShow = true">
        <div class="home-right-rank-title f-dfc f-fx1 f-fs14">榜单</div>
        <div class="home-right-rank-body f-dfc">
            <!-- <div class="rank1 f-pr"><img src="//fakeimg.pl/26x26" /></div>
            <div class="rank2 f-pr"><img src="//fakeimg.pl/26x26" /></div>
            <div class="rank3 f-pr"><img src="//fakeimg.pl/26x26" /></div> -->
        </div>
        <img src="@/assets/icon-arrow-right.png" class="home-right-rank-arrow" />
    </section>
    <div class="home-right-title f-fs16" @click="getMyRoomId">我的派对</div>
    <section id="home-right-list">
        <ul class="home-right-list">
            <template v-if="myChatRoom">
                <li class="f-dfc f-pr f-csp" v-if="!store.showCreateRoomDialog" @click="navigate(myChatRoom)">
                    <div class="f-pr home-right-list-img-wrap home-right-list-img-wrap0">
                        <img v-lazy="`${myChatRoom.cover}?x-oss-process=image/resize,m_lfit,h_60,w_60/format,webp`" class="home-right-list-img" />
                    </div>
                    <div class="home-right-list-desc">我的派对</div>
                </li>
                <li class="f-dfc f-pr f-csp" v-else @click="showDialog = !showDialog">
                    <div class="f-pr home-right-list-img-wrap home-right-list-img-create">
                        <img v-lazy="`${myChatRoom.cover}?x-oss-process=image/resize,m_lfit,h_60,w_60/format,webp`" class="home-right-list-img" />
                    </div>
                    <div class="home-right-list-desc">创建派对</div>
                </li>
            </template>

            <li v-for="(item, i) in state.listData" @click="navigate(item)" :key="i" class="f-dfc f-pr f-csp" :class="{ 'home-right-list-lock': item.isEncryption === 1 }">
                <!-- :style="`background-image:url(${item.cover})`"-->
                <div class="f-pr home-right-list-img-wrap" :class="`home-right-list-img-wrap${item.status}`">
                    <img v-lazy="`${item.cover}?x-oss-process=image/resize,m_lfit,h_60,w_60/format,webp`" v-if="item.cover" class="home-right-list-img" />
                    <!-- <img src="@/assets/icon-status1.png" v-if="" alt="正在直播" class="home-right-list-icon f-pa">
                    <img src="@/assets/icon-status0.png" v-else alt="" class="home-right-list-icon f-pa"> -->
                </div>
                <div class="home-right-list-desc f-toc3">
                    {{ item.theme }}
                </div>
            </li>
        </ul>
        <InfiniteScroll :handle-intersect="loadMore" v-model:no-more="state.noMore" root-selector="home-right-list" />
    </section>
    <van-popup v-model:show="state.isShow" width="580" class="f-cfff" position="right" teleport="body">
        <section class="f-h100 home-ranking" :class="[`home-ranking${state.rankingIndex}`]">
            <header class="home-ranking-head" :class="[`home-ranking-head${state.rankingIndex}`]">
                <div class="f-fx home-ranking-title f-csp">
                    <p :class="{ active: state.rankingIndex === 0 }" @click="tabRanking(0)">红人榜</p>
                    <p :class="{ active: state.rankingIndex === 1 }" @click="tabRanking(1)">礼物榜</p>
                    <p :class="{ active: state.rankingIndex === 2 }" @click="tabRanking(2)">派对榜</p>
                </div>
                <div class="f-fx f-fs14 home-ranking-sub-title f-csp" v-if="state.rankingIndex === 1">
                    <p :class="{ active: state.subIndex === 1 }" @click="giftRanking(1)">礼物之尊</p>
                    <p :class="{ active: state.subIndex === 2 }" @click="giftRanking(2)">礼物之魅</p>
                </div>
                <div class="f-fx f-fs14 home-ranking-sub-title f-csp" v-if="state.rankingIndex === 2">
                    <p :class="{ active: state.dateIndex === 1 }" @click="partyRanking(1)">日榜</p>
                    <p :class="{ active: state.dateIndex === 4 }" @click="partyRanking(4)">周榜</p>
                    <p :class="{ active: state.dateIndex === 2 }" @click="partyRanking(2)">月榜</p>
                </div>
                <div class="f-tac f-fs12 home-ranking-time f-op5">每周一00:00刷新数据</div>
                <div class="f-fx home-ranking-face f-fs12 f-tac">
                    <div v-if="state.rankingData.top[1]">
                        <img :src="`${state.rankingData.top[1].headPortrait}?x-oss-process=image/resize,m_lfit,h_60,w_60/format,webp`" alt="" />
                        <p class="f-toc1">{{ state.rankingData.top[1].nickName }}</p>
                        <p class="f-op5 f-fs11" v-if="state.rankingIndex === 1">{{ state.rankingData.top[1].giftName }}x{{ state.rankingData.top[1].giftCount }}</p>
                    </div>
                    <div class="f-vh" v-else></div>
                    <div v-if="state.rankingData.top[0]">
                        <img :src="`${state.rankingData.top[0].headPortrait}?x-oss-process=image/resize,m_lfit,h_60,w_60/format,webp`" alt="" />
                        <p class="f-toc1">{{ state.rankingData.top[0].nickName }}</p>
                        <p class="f-op5 f-fs11" v-if="state.rankingIndex === 1">{{ state.rankingData.top[0].giftName }}x{{ state.rankingData.top[0].giftCount }}</p>
                    </div>
                    <div v-if="state.rankingData.top[2]">
                        <img :src="`${state.rankingData.top[2].headPortrait}?x-oss-process=image/resize,m_lfit,h_60,w_60/format,webp`" alt="" />
                        <p class="f-toc1">{{ state.rankingData.top[2].nickName }}</p>
                        <p class="f-op5 f-fs11" v-if="state.rankingIndex === 1">{{ state.rankingData.top[2].giftName }}x{{ state.rankingData.top[2].giftCount }}</p>
                    </div>
                    <div class="f-vh" v-else></div>
                </div>
            </header>
            <section class="home-ranking-content">
                <ul class="home-ranking-list">
                    <li class="f-fx f-aic" v-for="(item, i) in state.rankingData.body" :key="i">
                        <p class="f-fs18 home-ranking-num f-tac f-op5">{{ (i += 4) }}</p>
                        <img v-lazy="`${item.headPortrait}?x-oss-process=image/resize,m_lfit,h_60,w_60/format,webp`" alt="" />
                        <div class="f-fs14 home-ranking-list-item f-fx1">
                            <p>{{ item.nickName }}</p>
                            <p class="f-op5" v-if="state.rankingIndex === 1">{{ item.giftName }}x{{ item.giftCount }}</p>
                        </div>
                    </li>
                </ul>
            </section>
        </section>
    </van-popup>
    <IframeActivity :src="state.jumpUrl" v-if="state.jumpUrl" @close="state.jumpUrl = ''" />
    <CreateRoomDialog v-model="showDialog" v-if="showDialog"></CreateRoomDialog>
    <blindBoxDialog v-model="showBlindBoxDialog" v-if="showBlindBoxDialog"></blindBoxDialog>
    <PlayNoticeDialog v-model="store.openPlayNoticeDialog"></PlayNoticeDialog>
</template>
<style lang="less" scoped>
.swiper-card {
    font-size: 0;
    height: 76px;
    margin-bottom: 10px;
    overflow: hidden;
}
#home-right-list {
    // height: 70vh;
    height: 0;
    flex-grow: 1;
    padding-bottom: 20px;
    overflow-y: scroll;
    font-size: 14px;
}
.home-right-title {
    margin: 10px 0;
}
.swiper-slide-img {
    width: 252px;
    height: 76px;
}
.home-right-rank {
    padding: 0 10px;
    border-radius: 25px;
}
.home-right-rank-title {
    &::before {
        content: "";
        display: inline-block;
        width: 28px;
        height: 28px;
        background: url(@/assets/cup.png) no-repeat center center/100%;
        margin-right: 5px;
    }
}
.home-right-rank-body {
    margin-right: 5px;
    div {
        height: 26px;
        &::before {
            content: "";
            z-index: 1;
            position: absolute;
            top: -4px;
            left: -5px;
            width: 10px;
            height: 11px;
        }
        img {
            width: 26px;
            height: 26px;
            border-radius: 13px;
            transform: translateX(-4px);
        }
    }
}
.rank1::before {
    background: url(@/assets/icon-rank1.png) no-repeat top left/100%;
}
.rank2::before {
    background: url(@/assets/icon-rank2.png) no-repeat top left/100%;
}
.rank3::before {
    background: url(@/assets/icon-rank3.png) no-repeat top left/100%;
}
.home-right-list-lock::after {
    content: "";
    position: absolute;
    right: 8px;
    top: 6px;
    width: 14px;
    height: 14px;
    background: url(@/assets/icon-lock.png) no-repeat top left/100%;
}
.home-right-list {
    li {
        height: 78px;
        background-color: @bg-white01;
        border-radius: 8px;
        margin-bottom: 4px;
        padding: 10px 14px;
    }
}
.home-right-list-img-wrap {
    height: 60px;
    margin-right: 20px;
    &::after {
        content: "";
        position: absolute;
        bottom: 0px;
        right: 0;
        width: 12px;
        height: 12px;
    }
}
.home-right-list-img {
    width: 60px;
    height: 60px;
    border-radius: 20px;
}
.home-right-list-img-wrap1 {
    &::after {
        background: url(@/assets/icon-status1.png) no-repeat center center/100%;
    }
}
.home-right-list-img-wrap0 {
    &::after {
        background: url(@/assets/icon-status0.png) no-repeat center center/100%;
    }
}
.home-right-list-img-create {
    &::after {
        width: 14px;
        height: 14px;
        right: -2px;
        background: url(@/assets/home/icon-create.png) no-repeat center center/100%;
    }
}
.home-right-rank {
    width: 254px;
    height: 44px;
    background-color: @bg-white01;
}
.home-ranking {
    width: 580px;
    background-color: #171625;
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100%;
}
.home-ranking0 {
    background-image: url("@/assets/rank-bg2.jpg");
    .home-ranking-face {
        padding: 0 110px;
        div {
            &:first-child {
                margin-top: 70px;
            }
            &:last-child {
                margin-top: 90px;
            }
        }
    }
    .home-ranking-time {
        margin: 40px 0 50px;
    }
}
.home-ranking1 {
    background-image: url("@/assets/rank-bg1.jpg");
    .home-ranking-face {
        padding: 0 20px;
        div {
            &:first-child,
            &:last-child {
                margin-top: 50px;
            }
        }
    }
    .home-ranking-time {
        margin-bottom: 20px;
    }
}
.home-ranking2 {
    background-image: url("@/assets/rank-bg0.jpg");
    .home-ranking-face {
        padding: 0 110px;
        div {
            &:first-child {
                margin-top: 70px;
            }
            &:last-child {
                margin-top: 90px;
            }
        }
    }
    .home-ranking-time {
        margin-bottom: 20px;
    }
}
.home-rank-popup {
    background-color: @body-bg;
}
.home-ranking-face {
    div {
        width: 80px;
        height: 80px;
        padding-top: 10px;
        // background-color: red;
        border-radius: 65px;
        position: relative;
        &::after {
            content: "";
            width: 77px;
            height: 77px;
            position: absolute;
        }
        &:first-child::after,
        &:last-child::after {
            width: 75px;
            height: 75px;
            top: 2px;
            left: 2px;
        }
        &:first-child::after {
            background: url(@/assets/rank-hbg1.png) no-repeat center center/100%;
        }
        &:last-child::after {
            background: url(@/assets/rank-hbg2.png) no-repeat center center/100%;
        }
        &:nth-child(2)::after {
            width: 77px;
            height: 77px;
            top: 0;
            left: 0;
            background: url(@/assets/rank-hbg0.png) no-repeat center center/100%;
        }
    }
    img {
        width: 60px;
        height: 60px;
        border-radius: 45px;
    }
}
.home-ranking-head {
    height: 395px;
    > div {
        justify-content: space-evenly;
    }
}
.home-ranking-time {
    background-color: @color-white01;
}
.home-ranking-title {
    padding: 36px 0 0;
    p {
        opacity: 0.5;
        &.active {
            opacity: 1;
            &::after {
                content: "";
                width: 10px;
                height: 2px;
                background: linear-gradient(135deg, #a283f9 0%, #7b4dfd 100%);
                border-radius: 1px;
            }
        }
    }
}
.home-ranking-sub-title {
    opacity: 0.5;
    margin: 20px 0;
    p {
        &.active {
            opacity: 1;
            color: #a283f9;
        }
    }
}
.home-ranking-head0 {
    background: url("@/assets/rank-tbg0.png") no-repeat bottom center/100%;
}
.home-ranking-head1 {
    background: url("@/assets/rank-tbg1.png") no-repeat bottom center/100%;
}
.home-ranking-head2 {
    background: url("@/assets/rank-tbg2.png") no-repeat bottom center/100%;
}
.home-ranking-content {
    height: 58vh;
    margin: 0 20px;
    overflow-y: scroll;
}
.home-ranking-list {
    li {
        margin-bottom: 18px;
    }
    img {
        width: 60px;
        height: 60px;
        border-radius: 30px;
        margin: 0 12px;
    }
}
.home-ranking-num {
    width: 30px;
}
.home-ranking-list-item {
    border-bottom: 1px solid @color-white01;
    // padding-bottom: 18px;
    p {
        margin: 10px 0;
    }
}
.home-right-list-desc {
    line-height: 1.6;
}

.activity-card {
    font-size: 0;
    margin-bottom: 10px;
    width: 100%;
    > img {
        width: 100%;
    }
}
</style>
